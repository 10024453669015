import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Card, Button} from 'react-bootstrap';
import moment from 'moment';


function LBProjectsListStripe() {
    let [ projectsList, setProjectsList ] = useState();
    let [ listLoading, setListLoading ] = useState();

    const loadProjList = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=Projects`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setProjectsList(jsonresponse.items);
    }

    useEffect( () => {
        setListLoading(true);
        try {
            loadProjList();
            setListLoading(false);
        } catch ( error ) {
            console.log(error);
            setListLoading(false);
        }
    },[]);

    return (
        <>
            <Row className="projects-list-stripe">
                    {
                        listLoading || !projectsList ?
                            <Spinner animation="border"/>
                        :
                            projectsList.map( (thisProject, index) => {
                                return (
                                        <Col sm={12} md={6} lg={4} className="">
                                            <Card className="project-card linked-image-card">
                                                {
                                                  thisProject.fields.projectpicture && thisProject.fields.projectpicture.url ?
                                                    <a href={thisProject.fields.link ? thisProject.fields.link.href : ''} target="_blank" rel="noreferrer">
                                                        <Card.Img variant="top" src={thisProject.fields.projectpicture ? thisProject.fields.projectpicture.url : 'https://via.placeholder.com/228'} className="linked-image-pic"/>
                                                    </a>
                                                : null 
                                                }      
                                            
                                                <Card.Title className="h4"> { thisProject.fields.title } </Card.Title>
                                                <h6 className='lb-orange-text'>{thisProject.fields.date ? moment(thisProject.fields.date).format("LL") : null} </h6>
                                                <h6 className='lb-orange-text'>{thisProject.fields.client ? (thisProject.fields.client) : null} </h6>
                                                <h6 className='lb-orange-text'>{thisProject.fields.amount ? (thisProject.fields.amount) : null} </h6>
                                                 <h6 className='lb-orange-text'>{thisProject.fields.type === "1" ? "Portfolio" :  
                                                    thisProject.fields.type  === "2" ? "Grant Received" :
                                                    thisProject.fields.type  === "3" ? "Grant Given"
                                                    : null
                                                }
                                                </h6>
                                                <Card.Body>
                                                    <Card.Text>{ thisProject.fields.body }</Card.Text>
                                                {
                                                  thisProject.fields.projectpicture && thisProject.fields.projectpicture.url ?
                                                    <a href={thisProject.fields.link ? thisProject.fields.link.href : ''} target="_blank" rel="noreferrer">
                                                        <Button className="btn-blue">View</Button> 
                                                    </a>
                                                : null 
                                                }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                )
                            })
                    }
            </Row>
        </>
    )
}

export default LBProjectsListStripe