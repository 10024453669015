import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbCareersPage({ stripes }) {
    
    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Careers Title Large',
                className: 'careers-title-large',
                key: 'careers-title-large'
            }]
        },
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Careers Title Small',
                className: 'careers-title-small d-lg-none',
                key: 'careers-title-small'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Overview',
                    className: 'standard-text-pic-card',
                    key: 'careers-overview'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Quality Talent',
                    className: 'careers-talent-card',
                    key: 'careers-quality-talent'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Quality Character',
                    className: 'careers-talent-card',
                    key: 'careers-quality-character'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Quality Interest',
                    className: 'careers-talent-card',
                    key: 'careers-quality-interest'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Benefits Stripe',
                    className: 'standard-text-both-sides-card benefits-stripe',
                    key: 'careers-benefits-stripe'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Salaries',
                    className: 'standard-text-both-sides-card benefits-stripe right-card',
                    key: 'careers-salaries'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Open Positions Title',
                    className: 'careers-open-positions-title',
                    key: 'careers-open-positions-title'
                }
            ]
        },
        // {
        //     contents: [
                // {
                //     type: CONTENT_TYPES.STRIPE,
                //     name: 'Careers Open Positions 1',
                //     className: 'job-list',
                //     key: 'careers-open-positions-1'
                // }
                // {
                //     type: CONTENT_TYPES.STRIPE,
                //     name: 'Careers Open Positions 2',
                //     className: 'job-list',
                //     key: 'careers-open-positions-2'
                // },                
                // {
                //     type: CONTENT_TYPES.STRIPE,
                //     name: 'Careers Open Positions 3',
                //     className: 'job-list',
                //     key: 'careers-open-positions-3'
                // }
            // ],
        // },
        // {
        //     contents: [
        //         {
        //             type: CONTENT_TYPES.STRIPE,
        //             name: 'Careers Future Opportunities Title',
        //             className: 'careers-open-positions-title',
        //             key: 'careers-future-opportunities-title'
        //         }
        //     ]
        // },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Future Opportunities 1',
                    className: 'job-list',
                    key: 'careers-future-opportunities-1'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Careers Future Opportunities 2',
                    className: 'job-list',
                    key: 'careers-future-opportunities-2'
                }                
                // {
                //     type: CONTENT_TYPES.STRIPE,
                //     name: 'Careers Future Opportunities 3',
                //     className: 'job-list',
                //     key: 'careers-future-opportunities-3'
                // }
            ]
            },

        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Recruitment Process',
                className: 'recruitment-process',
                key: 'recruitment-process'
            }]
        }
        
    ];

    


    let mergedContent = [];
    console.log('stripes', stripes)
    console.log('pageLayout', pageLayout)
    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='careers-page'>
            <LBPageComponent mergedContent={mergedContent} stripes={stripes} />
        </div>
    )
}

export default LbCareersPage