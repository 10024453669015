import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbDataMethodPage({ stripes }) {

    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'data methodology title',
                className: 'title-card'
            }]
        },
        {
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'data methodology second row',
                className: 'standard-text-pic-card'
            }]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <>
            <LBPageComponent mergedContent={mergedContent} />
        </>
    )
}

export default LbDataMethodPage
