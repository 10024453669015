import React from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
// import utils from '../utils/utils';

function OpenPositionsStripe({className}) {
    
    return (
            <Row className={`${className ? className: ''} content-job-stripe-row`}>
            <h3>Current Openings</h3>
                <Row>
               
                    <Col className='breezy-positions'  xs={12} lg={4}>
                        <Card className="open-positions job-list" >
                            <Card.Title>{`Engagement Manager (mid-career)`}</Card.Title>
                            <Card.Body>
                                <p>You've been working in your field for a while now, but you're looking to apply your expertise to a new, impactful challenge.</p>
                                <Button className="btn-orange" href='https://linksbridge.breezy.hr/p/ab2bc6042e8f-engagement-manager' rel="noreferrer" target="_blank">Apply</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='breezy-positions' xs={12} lg={4}>
                        <Card className="open-positions job-list" >
                            <Card.Title>{`Trainee Consultant (early career)`}</Card.Title>
                            <Card.Body>
                                <p>You have a post-graduate degree or, if not, at least a modicum of professional experience after college, and you're looking to hone your skills into a meaningful career.</p>
                                <Button className="btn-orange" href='https://linksbridge.breezy.hr/p/7e99b6073408-trainee-consultant' rel="noreferrer" target="_blank">Apply</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='breezy-positions' xs={12}  lg={4}>
                        <Card className="open-positions job-list" >
                            <Card.Title>{`Graduate Internship (gaining experience)`}</Card.Title>
                            <Card.Body>
                                <p>You are still pursuing an education, but you're excited about gaining new skills and working in global health and development.</p>
                                <Button className="btn-orange" href={`https://linksbridge.breezy.hr/p/a3d294874dbe-graduate-internship`} rel="noreferrer" target="_blank">Apply</Button>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Row>
    )
}

export default OpenPositionsStripe
