import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbAboutUsPage({ stripes }) {
    
    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Hero Banner',
                className: 'title-card',
                key: 'about-us-hero-banner'
            }]
        },
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Title Small',
                className: 'about-us-title-small',
                key: 'about-us-title-small'
            }]
        },
        {
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Our Story',
                className: 'about-us-our-story',
                key: 'about-us-our-story'
                }]
        },
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Milestones Title',
                className: 'about-us-milestones-title',
                key: 'about-us-milestones-title'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: '2008',
                    className: 'about-us-milestones-card',
                    key: 'about-us-milestone-2008'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: '2013',
                    className: 'about-us-milestones-card',
                    key: 'about-us-milestone-2013'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: '2021',
                    className: 'about-us-milestones-card',
                    key: 'about-us-milestone-2021'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: '2023',
                    className: 'about-us-milestones-card',
                    key: 'about-us-milestone-2023'
                }
            ]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Team Introduction',
                    className: 'team-intro',
                    key: 'team-intro'
            }]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.TEAM_LIST,
                    name: 'Our Team',
                    className: 'team-list'
            }]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us After Team Text',
                    className: 'after-team-text',
                    key: 'after-team-text'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Values Title',
                    className: 'values-title',
                    key: 'values-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.VALUES_LIST,
                    name: 'Linksbridge Values',
                    className: 'small-repeated-value'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Our Offices Title',
                    className: 'our-offices-title',
                    key: 'our-offices-title'
                },
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Our Offices Seattle',
                    className: 'our-offices-address',
                    key: 'our-offices-seattle' 
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Our Offices DC',
                    className: 'our-offices-address',
                    key: 'our-offices-dc' 
                }
            ]
        },
        {
            contents: [
            {
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Our Offices Seattle IMG',
                className: 'our-offices-img',
                key: 'our-offices-seattle-img' 
            },
            {
                type: CONTENT_TYPES.STRIPE,
                name: 'About Us Our Offices DC IMG',
                className: 'our-offices-img',
                key: 'our-offices-dc-img' 
            }
            ]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Learn More About Linksbridge Title',
                    className: 'learn-more-about-lb-title',
                    key: 'learn-more-about-lb-title'
            }]
        },
        { 
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Learn More Our Ecosystem',
                    className: 'learn-more-ecosystem',
                    key: 'learn-more-ecosystem'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Careers',
                    className: 'about-us-careers',
                    key: 'about-us-careers'
                },
            ]
        }
    ];


    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='about-us-page'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbAboutUsPage
