import React from 'react';
import { Spinner } from 'react-bootstrap';
import StoryTeaserLine from './StoryTeaserLine';

function NewsTeasers({ stories, showNumber, showImage = false, loadingTracker, className, urlprefix, showTags, trackingInfo=null }) {

    const convertToStoryTeaser = (storyToConvert) => {
        
        //figure out reading time based on average reading speed of 200 words per minute.
        let timeInMinutes = 0;
        const numwords = storyToConvert.fields.body.split(/\s/).length;
        
        if(numwords > 0){
            timeInMinutes = Math.ceil(numwords / 200) + " min";
        } else {
            timeInMinutes = "less than a minute"
        }

        storyToConvert.readTime = timeInMinutes;

        storyToConvert.fields.showNoImage = !showImage;

        return storyToConvert;
    }

    let myStories = stories.map( (thisStory) => {
            let theStory = thisStory.item ?  thisStory.item : thisStory;
            return convertToStoryTeaser(theStory);
        });

    return (
        <div className={className ? className : ''}>
            { 
                loadingTracker?
                    <Spinner animation="border" />
                    :
                    myStories ?
                        myStories.map( (thisStory, index) => {
                            let theStory = thisStory.item ?  thisStory.item : thisStory;
                            return index <= (showNumber-1) ? 
                                <StoryTeaserLine 
                                    key={index}
                                    story={theStory} 
                                    urlprefix={ urlprefix } 
                                    showTags={showTags} 
                                    trackingInfo={trackingInfo} />
                            : null
                        })
                    :
                    <p>No stories found</p>
            }
        </div>
    )
}

export default NewsTeasers
