import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import ImageOverlayWrapper from './ImageOverlayWrapper';
import { CONTENT_TYPES } from '../utils/pageConsts';
import utils from '../utils/utils';

import NewsletterSignUpForm from './NewsletterSignUpForm';

function ContentStripe({ contentsArray }) {

    return (
        <Row className="content-stripe-row " sm={12}>
            {
                contentsArray.map( (thisItem, index) => {
                    
                    return (
                        <Col  key={index} md={12} lg={thisItem.className === 'careers-talent-card' ? 4 
                        : thisItem.className === 'about-us-milestones-card' ? 3
                        : thisItem.className === 'job-list' && contentsArray.length === 1 ? 6
                        : contentsArray.length >=3 ? 4
                        : contentsArray.length > 1 ? 6
                        : 12}>
                            {
                                thisItem.type === CONTENT_TYPES.TEASER ?
                            
                                <Card className={thisItem.className}>
                                    { thisItem.fields && thisItem.fields.image ? <Card.Img src={thisItem.fields.image.url} alt={thisItem.fields.image.altText} /> : null }
                                    <Card.Body>
                                        { thisItem.fields && thisItem.fields.subtitle ? <Card.Text>{ thisItem.fields.subtitle }</Card.Text>: null }
                                        { thisItem.fields && thisItem.fields.title ? <Card.Title>{ thisItem.fields.title }</Card.Title>: null }
                                        { thisItem.fields && thisItem.fields.body ? <Card.Text>{ thisItem.fields.body }</Card.Text>: null }
                                        { thisItem.fields && thisItem.fields.subText ? <Card.Text>{ thisItem.fields.subText }</Card.Text>: null }
                                        { 
                                            thisItem.fields && thisItem.fields.ctaText ? 
                                                <Button 
                                                    className="main-cta" 
                                                    href={ thisItem.fields.ctaLink }
                                                    target={ thisItem.fields.linkinnewwindow && thisItem.fields.linkinnewwindow === 'true' ? "_blank": ""} >
                                                    { thisItem.fields.ctaText }
                                                </Button>
                                            : null }
                                        { thisItem.fields && thisItem.fields.lowerRightCTAtext ? <Button className='hiring-btn' href={ thisItem.fields.lowerRightCTALink }>{ thisItem.fields.lowerRightCTAtext }</Button>: null }
                                    </Card.Body>
                                </Card>

                                :
                                
                                <Card className={(thisItem.className ? thisItem.className : '') + ' ' + (thisItem.fields.styleOfStripe ? thisItem.fields.styleOfStripe : "") } xs={12} sm={6}>
                                    { thisItem.fields &&  thisItem.fields.image ? <Card.Img src={thisItem.fields.image.url} alt={thisItem.fields.image.altText} /> : "" }
                                    {
                                        thisItem.type === CONTENT_TYPES.SIGNUP_TEASER ?
                                            <div class="card-inner-text">    
                                                {  thisItem.fields && thisItem.fields.title ? <h5>{thisItem.fields.title}</h5> : "" }
                                                {  thisItem.fields && thisItem.fields.body ? <p>{thisItem.fields.body}</p>: "" }
                                                <NewsletterSignUpForm />
                                            </div>
                                        :
                                            <ImageOverlayWrapper
                                                condition={thisItem.fields.styleOfStripe} 
                                                wrapper={Children => <Card.ImgOverlay>{Children}</Card.ImgOverlay>}>
                                                    {  thisItem.fields && thisItem.fields.textcontents1 ? <Card.Text className="txtcontents1" dangerouslySetInnerHTML={ utils.getHTML(thisItem.fields.textcontents1) }></Card.Text> : "" }
                                                    {  thisItem.fields && thisItem.fields.textcontents2 ? <Card.Text className="txtcontents2" dangerouslySetInnerHTML={ utils.getHTML(thisItem.fields.textcontents2) }></Card.Text> : "" }
                                            </ImageOverlayWrapper>
                                    }  
                                </Card>
                            }
                        </Col>
                    )
                })
            }
        </Row>
    )
}

export default ContentStripe
