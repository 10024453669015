import React from 'react'
import { Card, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeaturePageDisplay from './FeaturePageDisplay';

function SearchResultsFeatureWrapper({featurePageInput}) {

  const getfeaturePageFormat = (featurePages) => {

    return (
      {
        pageTitle: featurePages.fields.pageTitle ? featurePages.fields.pageTitle :'',
        authorName: featurePages.fields.authorName ? featurePages.fields.authorName : '',
        datePublished: featurePages.fields.datePublished ? featurePages.fields.datePublished : '',
        bannerImage: featurePages.fields.bannerImage ? featurePages.fields.bannerImage : '',
        mainContent: featurePages.fields.mainContent ? featurePages.fields.mainContent : '',
        articlePreviewText: featurePages.fields.articlePreviewText ? featurePages.fields.articlePreviewText : '',
        tags: featurePages.fields.tags ? featurePages.fields.tags : '',
        channels: featurePages.fields.tags ? featurePages.fields.tags : '',
        pageKeyURL: featurePages.fields.pageKeyURL ? featurePages.fields.pageKeyURL : ''
      }
        
    );
}

  return (
    featurePageInput && featurePageInput.length > 0 ?

    <Container className="search-results-topic-nodes-stripe">
      {
        featurePageInput.map((feature, index) => {
          return (
            index < 2 ?
              <Col>
                <Card className="news-topic-node-preview">
                  {console.log('feature ', feature)}
                  <Link to={`/features/${feature.fields.pageKeyURL}`}>
                    <FeaturePageDisplay featureToDisplay={getfeaturePageFormat(feature)}  preview={true} />
                  </Link>
                </Card>
              </Col>
            : ''
          )
        })
      }
    </Container>
    : ''
  )
}

export default SearchResultsFeatureWrapper;