import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';
import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbNewsPage({ stripes, blocks, misc }) {
    //if passed a content id, then display article interface
    //else, show full news page

    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'News Hero Banner',
                key: 'news-hero-banner',
                className: 'news-hero-banner'

            }]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.NEWS_TEASER,
                    title: 'Latest News',
                    className: 'latest-news-card'
                }],
        },
           
        {
            contents: [{
                    type: CONTENT_TYPES.NEWS_EXPLORE,
                    title: 'Explore',
                    className: 'news-explore-card'
                }]
        },
        {
            contents: [{
                    type: CONTENT_TYPES.SIGNUPBOX,
                    title: 'sign-up box title text',
                    className: 'sign-up-card'
                }]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], blocks, stripes);
        })
    }

    
    return (
        <div className='news-page'>
            <LBPageComponent mergedContent={mergedContent} 
                optionalDepContent={misc}/>
        </div>
        )
}

export default LbNewsPage
