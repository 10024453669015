import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContentStripe from './ContentStripe';
import TeamListStripe from './TeamListStripe';
import LBValuesStripe from './LBValuesStripe';
import ProjectsListStripe from './ProjectsListStripe';
import NewsStripe from './NewsStripe';
import OpenPositionsStripe from './OpenPositionsStripe';
import FullNewsPageStripe from './FullNewsPageStripe';
import TrendingTopicsNavStripe from './TrendingTopicsNavStripe';
import NoMatchStripe from './NoMatchStripe';
import FaqStripe from './FaqStripe';
import EditStripe from './EditStripe';
import EditStripeHubSpotV2 from './EditStripeHubspotV2';
import EditStripeHubspotBMGF from './EditStripeHubspotBMGF';
import { CONTENT_TYPES } from '../utils/pageConsts';
import utils from '../utils/utils';
import LBLinkedImageGridStripe from './LBLinkedImageGridStripe';
import HubspotSupportFormStripe from './HubspotSupportFormStripe';
import IubendaPoliciesStripe from './IubendaPoliciesStripe';
import EditStripeHubSpot from './EditStripeHubspot';

function LBPageComponent({ mergedContent, optionalDepContent, stripes }) {
    const location = useLocation();

    useEffect( () => {
        //this sends the page view to google analytics
        utils.gaPageView(location.pathname);
    });
    
    return (
        mergedContent.map( (contentItem, index) => {
            if(contentItem[0].type === CONTENT_TYPES.TEAM_LIST) {
                return <TeamListStripe key={index} />
            }
            
            if(contentItem[0].type === CONTENT_TYPES.VALUES_LIST) {
                return <LBValuesStripe key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.PROJECTS_LIST) {
                return <ProjectsListStripe key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.NEWS_STRIPE) {
                return <NewsStripe stories={optionalDepContent.news} loadingTracker={optionalDepContent.loadingNews} keyForHighlightedArticle='LATEST' key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.JOB_LIST) {
                return <OpenPositionsStripe contentsArray={contentItem} stripes={stripes} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.SIGNUPBOX) {
                return <FullNewsPageStripe type="signupBox" contentsArray={contentItem}  misc={optionalDepContent} key={index} />
            }
            
            if(contentItem[0].type === CONTENT_TYPES.NEWS_TEASER) {
                return <FullNewsPageStripe contentsArray={contentItem}  misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.NEWS_EXPLORE) {
                return <FullNewsPageStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.TRENDING_TOPICS_STRIPE) {
                return <TrendingTopicsNavStripe misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.LINKED_IMAGE_STRIPE_GIFTS) {
                return <LBLinkedImageGridStripe listToPull={CONTENT_TYPES.LINKED_IMAGE_STRIPE_GIFTS} title="" key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.NO_MATCH) {
                return <NoMatchStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.FAQ) {
                return <FaqStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.STORY_EDIT_LIST) {
                return <EditStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.HUBSPOT_EDIT_LIST) {
                return <EditStripeHubSpot contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.HUBSPOT_EDIT_LIST_V2) {
                return <EditStripeHubSpotV2 contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.HUBSPOT_EDIT_LIST_BMGF) {
                return <EditStripeHubspotBMGF contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            if(contentItem[0].type === CONTENT_TYPES.SUPPORT_FORM) {
                return <HubspotSupportFormStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }


            if(contentItem[0].type === CONTENT_TYPES.POLICIES) {
                return <IubendaPoliciesStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
            }

            return <ContentStripe contentsArray={contentItem} misc={optionalDepContent} key={index} />
        })
    )
}

export default LBPageComponent
