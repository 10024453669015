import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';
import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbEcosystemPage({ stripes }) {
    
    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Ecosystem Title',
                className: 'title-card',
                key: 'ecosystem-title'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Title Small',
                    className: 'ecosystem-title-small',
                    key: 'ecosystem-title-small'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Transparency Reports Title',
                    className: 'transparency-reports-title',
                    key: 'transparency-reports-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Transparency Report 2022',
                    className: 'transparency-report-card',
                    key: 'transparency-report-2022'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Transparency Report 2021',
                    className: 'transparency-report-card',
                    key: 'transparency-report-2021'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Linksbridge Foundation Title',
                    className: 'ecosystem-foundation-title',
                    key: 'ecosystem-foundation-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Linksbridge Foundation Left Card',
                    className: 'ecosystem-foundation-card',
                    key: 'ecosystem-foundation-card-left'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Linksbridge Foundation Right Card',
                    className: 'ecosystem-foundation-card',
                    key: 'ecosystem-foundation-card-right'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Ecosystem Foundation Gifts Given Title',
                    className: 'ecosystem-foundation-gifts-given-title',
                    key: 'ecosystem-foundation-gifts-given-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.LINKED_IMAGE_STRIPE_GIFTS,
                    name: 'Gifts Given',
                    className: 'gifts-given-list'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'About Us Duwamish Acknowledgement',
                    className: 'bleed-width-card-orange',
                    key: 'about-us-duwamish-acknowledgement'
                }
            ]
        },

    ];


    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='ecosystem-page'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbEcosystemPage
