import React, { useState, useEffect } from 'react';
import { Route, NavLink, Switch, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Container, Row, Navbar, Image, Nav, Spinner } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

import LBMainFooter from '../../components/LBMainFooter';
// import LBMainNavbar from '../../components/LBMainNavbar';
import SearchBox from '../../components/SearchBox';

import LbNewsPage from './LbNewsPage';
import LbOurWorkPage from './LbOurWorkPage';
import LbDataMethodPage from './LbDataMethodPage';
import LbAboutUsPage from './LbAboutUsPage';
import LbCareersPage from './LbCareersPage';
import LbNoMatchPage from './LbNoMatchPage';
import LbHelpPage from './LbHelpPage';
import LbEditPage from './LbEditPage';
import LbHubspotEditPage from './LbHubspotEditPage';
import HomePage from './HomePage';
import LbDataUsePage from './LbDataUsePage';
import LbEcosystemPage from './LbEcosystemPage';
import FeaturePagePage from '../../components/FeaturePagePage';
import LbPrivacyPolicyPage from './LbPrivacyPolicyPage';

import utils from '../../utils/utils';

import LbNewsTopicsPage from './LbNewsTopicsPage';
import LbNewsArticlePage from './LbNewsArticlePage';
import SearchResultsPage from './SearchResultsPage';
import LatestNewsPage from './LatestNewsPage';

import LbArticlePreviewPage from './LbArticlePreviewPage';
import LbHubspotEditPageV2 from './LbHubspotEditPageV2';
import TopicNodePage from '../../components/TopicNodePage';
import LbHubspotEditPageBMGF from './LbHubspotEditPageBMGF';
// import { PersonCircle } from 'react-bootstrap-icons';

function MainPlatformPage() {
    
    // eslint-disable-next-line
    let [ accessToken, setAccessToken ] = useState();
    // eslint-disable-next-line
    let [ loadingToken, setLoadingToken ] = useState(false);

    let [ stripes, setStripes ] = useState();
    let [ blocks, setBlocks ] = useState();
    let [ miscContent, setMiscContent ] = useState();
    // let [ showSearch, setShowSearch ] = useState(false);
    let [ searchTerm, setSearchTerm ] = useState();

    let [ loadingBlocks, setLoadingBlocks ] = useState(true);
    let [ loadingStripes, setLoadingStripes ] = useState(true);
    let [ loadingMisc, setLoadingMisc ] = useState(true);

    let { path } = useRouteMatch();
    let history = useHistory();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState(location.pathname); // Manage activeKey state


    const [active, setActive] = useState('home');
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    
//   const loginRequest = {
//     scopes: ["User.Read"]
//   };

    // const showLogoutPopup = () => {
    //     try {
    //       instance.logoutPopup().catch(error => {
    //         console.log(error);
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }
    // };

    // const showLoginPopup = () => {
    //     try {
    //       instance.loginPopup(loginRequest).catch(error => {
    //         console.log(error);
    //       });
    //     } catch (error) {
    //       console.log(error);
    //     }

    //   };

    const handleSearchSubmit = ( searchStr ) => {
        //this sets the searchstr in state and then navs to search page
        setSearchTerm(searchStr);
        history.push('/search');
    }

    const loadStripes = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=LBContentStripes`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setStripes(jsonresponse.items);
    }

    const loadMisc = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=miscellaneous`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setMiscContent(utils.convertArrayToObject(jsonresponse.items));
    }

    const loadBlocks = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=mainpageteasers`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setBlocks(jsonresponse.items);
    }

    const getAccessToken = async () => {
        const request = {
            scopes: ["User.Read"],
            redirectUri: "http://localhost:3000/signed-in"
        };

        let tokenResp;

        try {
            tokenResp = await instance.acquireTokenSilent(request);
            setAccessToken(tokenResp);
        } catch( error ){
            // fallback to interaction when silent call fails
            tokenResp = await instance.acquireTokenRedirect(request);
            setAccessToken(tokenResp);

        }
    }

    const setUserIdentity = () => {
        if(isAuthenticated) {
            utils.setUserForAnalytics(accounts[0]);
        }
    }

    useEffect( ()=> {
        setLoadingStripes(true);
        setLoadingBlocks(true);
        setLoadingMisc(true);
        try {
            if(isAuthenticated) {
                setUserIdentity();
                setLoadingToken(true);
                getAccessToken();
                setLoadingToken(false);
            }
            loadStripes();
            setLoadingStripes(false);
            loadBlocks();
            setLoadingBlocks(false);
            loadMisc();
            setLoadingMisc(false);
        } catch (error) {
            console.log(error); 
            setLoadingToken(false);
            setLoadingStripes(false);
            setLoadingBlocks(false);
            setLoadingMisc(false);
        }
        // eslint-disable-next-line
    },[]);

        // Update activeKey on location change
        useEffect(() => {
            setActiveKey(location.pathname);
        }, [location]);



    return (
        
        !loadingStripes && stripes && !loadingBlocks && blocks && !loadingMisc && miscContent ?
            <>
                <Container className="webpage fluid">
                    <Row className="navbar-row">
                        <Navbar bg="white" expand="xl" activeKey={active} onSelect={(selectedKey) => setActive(selectedKey)}>
                            <Container>
                                <Navbar.Brand>
                                    <NavLink to="/" exact>
                                        <Image
                                            id="nav-lb-icon"
                                            src="/linksbridge-icon.png"
                                            alt="Linksbridge, SPC, Seattle WA"
                                            width="100"
                                        />
                                    </NavLink>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="navbar-collapse">
                                    <Nav className="me-auto">
                                        <Nav.Link
                                            as={NavLink}
                                            to="/"
                                            exact
                                            activeClassName="active"
                                            className={({ isActive }) => isActive ? 'active' : ''}
                                            isActive={() => activeKey === '/'}>
                                            Home
                                        </Nav.Link>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/news"
                                            activeClassName="active"
                                            className={({ isActive }) => isActive ? 'active' : ''}
                                            isActive={() => activeKey === '/news'}>
                                            News
                                        </Nav.Link>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/our-work"
                                            activeClassName="active"
                                            className={({ isActive }) => isActive ? 'active' : ''}
                                            isActive={() => activeKey === '/our-work'}>
                                            Our Work
                                        </Nav.Link>
                                        <Nav.Link
                                            as={NavLink}
                                            to="/about-us"
                                            activeClassName="active"
                                            className={({ isActive }) => isActive ? 'active' : ''}
                                            isActive={() => activeKey === '/about-us'}>
                                            About Us
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="mr-sm-2 nav-search-box">
                                        <SearchBox
                                            className="nav-search-box me-2"
                                            onSubmit={(val) => handleSearchSubmit(val)}
                                        />
                                    </Nav>
                                </Navbar.Collapse>
                                <Nav>
                                    {
                                        // isAuthenticated ?
                                        //     <div className='btn-lg' onClick={showLogoutPopup}><PersonCircle />Sign Out</div>
                                        //     :
                                        //     <div className='btn-lg' onClick={showLoginPopup}><PersonCircle />Sign In</div>
                                    }
                                </Nav>
                            </Container>
                        </Navbar>
                    </Row>

                    <Switch>
                        <Route path={`${path}search`}>
                            <SearchResultsPage term={searchTerm} onTotalPagesReceived={()=> {}} />
                        </Route>
                        <Route path={`${path}careers`}>
                            <LbCareersPage stripes={stripes} />
                        </Route>
                        <Route path={`${path}news/`} exact>
                            <LbNewsPage stripes={stripes} blocks={blocks} misc={ miscContent }/>
                        </Route>
                        <Route path={`${path}news/:contentID`} exact>
                            <LbNewsArticlePage />
                        </Route>
                        <Route path={`${path}topic/:topicSlug`}>
                            <TopicNodePage />
                        </Route>
                        <Route path={`${path}features/:featureSlug`}>
                            <FeaturePagePage />
                        </Route>
                        <Route path={`${path}preview-news`} exact>
                            <LbArticlePreviewPage />
                        </Route>
                        <Route path={`${path}news/topics/:topicname`}>
                            <LbNewsTopicsPage onTotalPagesReceived={()=> {}} />
                        </Route>
                        <Route path={`${path}latest-news`}>
                            <LatestNewsPage onTotalPagesReceived={()=> {}} />
                        </Route>
                        <Route path={`${path}our-work`}>
                            <LbOurWorkPage stripes={stripes} />
                        </Route>
                        <Route path={`${path}data-use`}>
                            <LbDataUsePage stripes={stripes} />
                        </Route>
                        <Route path={`${path}privacy-policy`}>
                            <LbPrivacyPolicyPage stripes={stripes} />
                        </Route>
                        <Route path={`${path}data-methodology`}>
                            <LbDataMethodPage stripes={stripes}/>
                        </Route>
                        <Route path={'/help'}>
                            <LbHelpPage stripes={stripes}/>
                        </Route>
                        <Route path={`/about-us`}>
                            <LbAboutUsPage stripes={stripes}/>
                        </Route>
                        <Route path={`/ecosystem`}>
                            <LbEcosystemPage stripes={stripes}/>
                        </Route>
                        <Route path={'/edit'}>
                            <LbEditPage stripes={stripes}/>
                        </Route>
                        <Route path={'/edit-hubspot'}>
                            <LbHubspotEditPage stripes={stripes}/>
                        </Route>
                        <Route path={'/edit-hubspot-v2'}>
                            <LbHubspotEditPageV2 stripes={stripes}/>
                        </Route>
                        <Route path={'/edit-hubspot-bmgf'}>
                            <LbHubspotEditPageBMGF stripes={stripes}/>
                        </Route>
                        <Route path={'/ghmh-request'}
                            render={() => {
                                window.location.href = "https://share.hsforms.com/1BW5AKkioTDuqQ9fje-R3og54nwk"
                            }} />                            
                        <Route exact path={`${path}`}>
                            <HomePage blocks={blocks} stripes={stripes} misc={ miscContent }/>
                        </Route>
                        
                        <Route exact path={'*'}>
                            <LbNoMatchPage stripes={stripes} blocks={blocks} misc={ miscContent }/>
                        </Route>
                    </Switch>
                </Container>
                <footer className={`main-lb-footer`}>
                    <LBMainFooter contentItems={ miscContent }/>        
                </footer>
            </>
        :
        <Spinner />
        
    )
}

export default MainPlatformPage