import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Form, Button } from 'react-bootstrap';
import utils from '../utils/utils';

import 'rc-collapse/assets/index.css';
// import { getTTFB } from 'web-vitals';
// import { set } from 'react-ga';


function EditStripe() {

    let [ editList, setEditList ] = useState();
    let [ loadingEditList, setLoadingEditList ] = useState(false);
    let [ hoursNum, setHoursNum ] = useState(24);

    const loadEditList = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?formail=${hoursNum}&category=PharmaNormal&contenttype=articles`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_MAILING_KEY
            }
        });
        let jsonresponse = await response.json();

        sortNewsStickyWeight(jsonresponse.items);
    }
    
    const sortNewsStickyWeight = (jsonList) => {

        jsonList.forEach((item) => {
            if(!item.fields.stickyweight) {
                item.fields.stickyweight = 0;
            }
        })
        
        jsonList.sort(function(a, b) {
            
            return a.fields.stickyweight - b.fields.stickyweight; 
        })
        return setEditList(jsonList);
    }

    useEffect( ()=> {
        setLoadingEditList(true);
        try {
            loadEditList();
            setLoadingEditList(false);
        } catch (error) {
            console.log(error); 
            setLoadingEditList(false);
        }
    // eslint-disable-next-line
    },[hoursNum])



    const elemTags = (elemName) => {
       return String.fromCharCode(60) + (elemName) + String.fromCharCode(62);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHoursNum(e.target.elements.hours.value);
    }

    return (
        <>
        <Form onSubmit={handleSubmit}>
            <Form.Label>
                Number of hours to look back
            </Form.Label>
            <input name="hours" type="number" defaultValue={hoursNum} style={{margin: '15px'}}>
            </input>
            <Button type="submit">Submit</Button>
        </Form>
        <br /><br />
        <Col className="edit-section">
            <Row className="edit-list-main">
            {
                !loadingEditList && editList ?
                    <code>
                    <p style={{maxWidth: 1000}}>
                        {elemTags('strong')}New this week{elemTags('span data-ccp-parastyle="No Spacing"')}:{elemTags('/span')}{elemTags('/strong')}&amp;nbsp;<br/><br />
                        {
                            editList.map((thisStory, index) => {
                                const linkTag = thisStory.fields.title.split(" ").slice(0,3).join("_");
                                return (
                                    <>
                                        <>
                                        {elemTags(`a href="#_${linkTag}"`)}{thisStory.fields.title}{index === editList.length -1 ? null : ""}{elemTags('/a')};
                                        </>
                                        <br/>
                                    </>
                                )
                            })
                        } <br/>
                        {elemTags('br /')}{elemTags('br /')}<br/>
                        {elemTags('hr /')}<br/><br/>
                        {
                            editList.map((thisStory, index) => {
                                const linkTag = thisStory.fields.title.split(" ").slice(0,3).join("_");
                                let tagList = [];
                                thisStory.fields.tags.map((thisTag) => {
                                    return (
                                        tagList.push(thisTag.fields.tagtext)
                                    )
                                })
                                let storyDate = new Date(thisStory.fields.datepublished);
                                return (
                                    <>
                                        <>
                                            {elemTags(`a name="_${linkTag}"`)} {elemTags('/a')}{elemTags('span style="color:#000080"')}{elemTags('strong')}
                                            {thisStory.fields.title}{elemTags('/strong')}{elemTags('/span')}{elemTags('br /')}&nbsp;{elemTags('br /')}
                                            Mentions: {elemTags('em')}{tagList.join(", ")}{elemTags('/em')}{elemTags('br /')}&nbsp;{elemTags('br /')}
                                            {utils.getMonthNames()[storyDate.getMonth()] + " " + storyDate.getDate() + ", " + storyDate.getFullYear() + " &mdash;&nbsp;" + thisStory.fields.body}
                                            {elemTags('br /')}<br/>
                                            MORE INFORMATION:{elemTags('br /')}
                                            {thisStory.fields.links}                                  
                                        </>
                                        {index === editList.length -1 ? null : elemTags('hr /')}
                                    </>
                                )
                            })
                        }<br/><br/>
                            {elemTags('br /')}[ends]&nbsp;
                    </p>
                    </code>
                : 
                    <Container>
                        <Spinner animation="border"/>
                    </Container>
                    
            }
            </Row>
        </Col>
        </>
    )
}

export default EditStripe;