import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbHubspotEditPageV2({ stripes }) {

    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.HUBSPOT_EDIT_LIST_V2,
                name: 'hubspot edit list v2',
                className: 'hubspot-edit-list-v2'
            }]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <>
            <LBPageComponent mergedContent={mergedContent} />
        </>
    )
}

export default LbHubspotEditPageV2;