import React, {useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NewsTeasers from './NewsTeasers';
import utils from '../utils/utils';

function NewsLatestBox({ className, config }) {
    let [news, setNews] = useState();
    let [loadingNews, setLoadingNews] = useState(true);

    //config allows us to tweak the search, sort, etc of the news

    const loadNews = async () => {
        let response = await utils.getResultsFromElastic('linksbridge', 1, '', 10)
        setNews(response.results);
    }

    useEffect(() => {
        setLoadingNews(true);
        try {
            loadNews();
            setLoadingNews(false);
        } catch (error) {
            console.log(error);
            setLoadingNews(false);
        }
    }, [])

    return (
        <Card className={className}>
            <Card.Body className="latest-news">
                {
                    !loadingNews && news ?
                        <div>
                             <Link className="latest-news-title" to={`/latest-news`}>
                                <h2 className='latest-news-title'>Latest News</h2>
                            </Link>
                            <NewsTeasers
                                className="news-explore-topic-list"
                                urlprefix={'/news/'}
                                stories={news}
                                showNumber={5}
                                loadingTracker={loadingNews}/>
                            <div className="text-center">
                                <Link className="view-all" to={`/latest-news`}>View All Articles</Link>
                            </div>
                        </div>

                    : <Spinner animation="border" />
                }

            </Card.Body>
        </Card>
    )
}

export default NewsLatestBox
