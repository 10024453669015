import React from 'react';
import { Row } from 'react-bootstrap';
import { CONTENT_TYPES } from '../utils/pageConsts';

import LBSignUpBox from './LBSignUpBox';
import NewsLatestBox from './NewsLatestBox';
import NewsExploreBox from './NewsExploreBox';

import { stripHtml } from "string-strip-html";

function FullNewsPageStripe({ contentsArray, misc, type=null }) {
    //strip html out of misc for explore config
    let config_item = null;

    if(misc && misc['explore-config-items']) {
        config_item = stripHtml(misc['explore-config-items']);
    }

    return (
        <Row className="content-stripe-row full-news-stripe" xs={1} lg={type==="signupBox" ? 1 : 1}> 
            {
        contentsArray.map( (thisItem, index) => {
            return (
                        <>
                        {
                            thisItem.type === CONTENT_TYPES.SIGNUPBOX ?
                                <LBSignUpBox titleText={ misc ? misc['sign-up-box-title-text'] : '' } key={index} />
                            :
                            thisItem.type === CONTENT_TYPES.NEWS_TEASER ?
                                <NewsLatestBox key={index} />
                            :
                            <Row key={index} className="news-explore-box" >
                            {thisItem.type === CONTENT_TYPES.NEWS_EXPLORE ?
                                <NewsExploreBox exploreConfig={config_item} key={index}/>
                            : null}
                            </Row>
                        }
                        </>              
                    )
                })
            }
        </Row>
    )
}

export default FullNewsPageStripe
