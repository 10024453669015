import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Popover, OverlayTrigger, Spinner, Card } from 'react-bootstrap';
import { Linkedin } from 'react-bootstrap-icons';

function TeamListStripe({className}) {
    
    let [ teamList, setTeamList ] = useState();
    let [ loadingTeamList, setLoadingTeamList ] = useState(false);

    const sortList = (listToSort) => {
        return listToSort.sort( (a, b) => {
            let aname = a.fields.name.toUpperCase();
            let bname = b.fields.name.toUpperCase();
            
            if(aname < bname ){
                return -1;
            }

            if(aname > bname) {
                return 1;
            }

            return 0;
        })
    }

    const loadTeamList = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=EmployeeProfiles`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setTeamList(sortList(jsonresponse.items));
    }  

    useEffect( ()=> {
        setLoadingTeamList(true);
        try {
            loadTeamList();
            setLoadingTeamList(false);
            
        } catch (error) {
            console.log(error); 
            setLoadingTeamList(false);
        }
    // eslint-disable-next-line
    },[])

    return (
        <>
            
            <Row className={`${className ? className: ''} content-team-stripe-row`}>
                {
                    !loadingTeamList && teamList ?
                        <>
                        
                        { teamList.map( (thisMember, index) => {
                            return (
                                <OverlayTrigger
                                    rootClose 
                                    trigger="click"
                                    key={`popover-${index}`}
                                    placement="bottom"
                                    overlay={
                                        <Popover id={`popover-${index}`} style={{width: '500px'}}>
                                            <Popover.Content>
                                            <Container >
                                                <Row>
                                                    <Col>
                                                        <h5>{thisMember.fields.name}</h5>
                                                        <h6 className='lb-orange-text'>{thisMember.fields.title}</h6>
                                                        { thisMember.fields.linkedInProfileURL ?
                                                            <h6>
                                                                <a href={ thisMember.fields.linkedInProfileURL.href } target={ thisMember.fields.linkedInProfileURL.target }>
                                                                    <Linkedin /> { thisMember.fields.linkedInProfileURL.text }
                                                                </a>
                                                            </h6>
                                                        : null }
                                                        <p>
                                                            { thisMember.fields.biography }
                                                        </p>
                                                        <h6>Ask Me About</h6>
                                                        <ul>
                                                            {
                                                                thisMember.fields.expertise ?
                                                                    thisMember.fields.expertise.map( (thisExpert) => {
                                                                        return (
                                                                            <li>{ thisExpert.fields.label }</li>
                                                                        )
                                                                    })
                                                                : null
                                                            }
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Container>
                                            </Popover.Content>
                                        </Popover>} >
                                <Card className="team-member-card" >
                                    <Card.Img variant="top" src={thisMember.fields.picture? thisMember.fields.picture.url : 'https://via.placeholder.com/100'}/>
                                    <Card.Title>{ thisMember.fields.name }</Card.Title>
                                </Card>
                            </OverlayTrigger>
                            )
                        })  }  
                    
                        
                        </>

                        : <Container><Spinner animation="border"/></Container>

                }
                
                

            </Row>
        </>
    )
}

export default TeamListStripe
