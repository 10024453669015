import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';


//TopicNodeDisplay displays the topic nodes
// TopicNodeDisplay is nested within TopicNodePageWrapper.js (pulling from ElasticSearch), on TopicNodePage.js
// and nested within SearchResultsNodeWrapper.js (pulling from Agility), on SearchResultsPage.js

function TopicNodeDisplay({topicToDisplay}) {
  
  return (
    <Container className="topic-node-container">
        <Row>
            <Col>
              <h3 className="topic-node-title">{topicToDisplay.pageTitle} </h3>
            </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="topic-node-article-preview-text">{topicToDisplay.articlePreviewText} </h4>
          </Col>
      </Row> 
      <Row>
        <Col>
          <p className='topic-node-content' dangerouslySetInnerHTML={{__html: topicToDisplay.mainContent}}></p>
        </Col>
      </Row>       
  </Container>
   );
 }

 export default TopicNodeDisplay