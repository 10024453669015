import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';

import './IubendaPoliciesStripe.css';

function IubendaPoliciesStripe({ className }) {

    const myPolicyID = 62216506;

    const apiUrl = 'https://www.iubenda.com/api/';

    let [ termsAndConditions, setTermsAndConditions ] = useState();
    let [ privacyPolicy, setPrivacyPolicy ] = useState();
    let [ loadingPolicies, setLoadingPolicies ] = useState(true);

    const policySetter = {
        'privacyPolicy' : setPrivacyPolicy,
        'tAndC': setTermsAndConditions
    };

    const urlSettings = {
        'privacyPolicy': 'privacy-policy',
        'tAndC': 'terms-and-conditions'
    };

    const loadPolicy = async (pType) => {
        let response = await fetch(`${apiUrl}${urlSettings[pType]}/${myPolicyID}`);
        let jsonresponse = await response.json();

        policySetter[pType](jsonresponse);

    }

    useEffect( ()=> {
        setLoadingPolicies(true);
        try {
            loadPolicy('privacyPolicy');
            loadPolicy('tAndC');
            setLoadingPolicies(false);
        } catch (error) {
            console.log(error); 
            setLoadingPolicies(false);
        }
    // eslint-disable-next-line
    },[])

    return (
        !loadingPolicies && termsAndConditions && privacyPolicy ?
            <>
                <Row className={ className ? className: ''}>
                    <Col>
                        <div dangerouslySetInnerHTML={{__html: privacyPolicy.content}} />
                    </Col>
                </Row>
                <Row className={ className ? className: ''}>
                    <Col>
                        <div dangerouslySetInnerHTML={{__html: termsAndConditions.content}} />
                    </Col>
                </Row>
            </>
        : <Spinner animation="border" />
    )
}

export default IubendaPoliciesStripe
