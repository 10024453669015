import MainPlatformPage from './pagegroups/platform/MainPlatformPage';


const appRoutes = {
    LINKSBRIDGE_ROUTE: {
        path: "/",
        component: MainPlatformPage
    }
};

export default appRoutes;