import React, { useState } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import HubspotForm from 'react-hubspot-form';

function HubspotSupportFormStripe({ className }) {

    let [ formSubmitted, setFormSubmitted ] = useState(false);

    const submitForm = () => {
        setFormSubmitted(true);
    }

    const resetSubmit = () => {
        setFormSubmitted(false);
    }

    return (
        <Row className={ className ? className + 'hubspot-support-form-stripe': 'hubspot-support-form-stripe'}>
            <Col>
                <>
                    <h5 id="supportform">Submit a Support Request</h5>
                    { formSubmitted === true ?
                        <div className="submission-recog">
                            Your request has been received.  You should receive an email confirming the ticket.<br/><br/>
                            <Button onClick={ () => resetSubmit() }>Submit another Support Ticket</Button>
                        </div>
                    : null }
                    <HubspotForm
                        portalId='8615684'
                        formId='284314f7-456a-447a-913a-32866f3b754b'
                        onSubmit={ () => submitForm() }
                        loading={<Spinner animation="border" />}
                        />
                </>
            </Col>
        </Row>
    )
}

export default HubspotSupportFormStripe
