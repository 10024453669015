import React, { useState } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
// import { useMsal, useIsAuthenticated } from '@azure/msal-react';

// import utils from '../utils/utils';

function SearchBox({ /* show, onShowToggled, */ onSubmit }) {

    let [error, setError] = useState(null);

    // const { accounts } = useMsal();
    // const isAuthenticated = useIsAuthenticated(); 

   // let username = isAuthenticated && accounts && accounts.length > 0 ? accounts[0].username : 'UNKNOWN';

    const handleSubmit = () => {

        let curVal = inputBox.current.value;

        if(curVal && curVal.length >= 3) {
            setError(null);

            //log search term on our db
            // commenting out to remove 401 error
           //  utils.postEvent('SEARCHED_FOR', curVal, username, null);

            onSubmit(curVal);
        } else {
            raiseError('Search must be 3 characters');
        }

    }

    const raiseError = (errorText) => {
        setError(errorText);
    }

    // keyCode 13 is the "enter" key
    const keyPress = (event) => {
        if(event.keyCode === 13){
            handleSubmit();
        }
    }

    let inputBox = React.createRef();

    return (
        // show ?
            <>

                { error ? <span onClick={e => setError(null)} className="error-text floating">{ error }</span> : null}
                <Row>
                <Form.Control
                    style={{width: "200px"}} 
                    ref={inputBox}
                    type="text"
                    defaultValue={''} 
                    placeholder="Search"
                    onKeyDown={(e) => keyPress(e) }
                /> 
                <Button 
                    variant="light"
                    onClick={handleSubmit}
                    onMouseDown={e => e.preventDefault()}
                >
                    <Search />
                </Button>
                </Row>
            </> 
        // : 
        //     <Search onClick={ ()=> onShowToggled() }/>
    )
}

export default SearchBox
