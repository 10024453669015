import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

import { stripHtml } from "string-strip-html";

function TrendingTopicsNavStripe({ className, misc }) {

    //strip html out of misc for explore config
    let listOfTopics = [];

    if(misc && misc['trending-topics-list']) {
        listOfTopics = stripHtml(misc['trending-topics-list']).result.split(',');
    }

    return (
        <Nav className={(className ? className : '')+' trending-topics-nav'}>
            <h4 className="inline-title">TRENDING TOPICS</h4>
            { 
                listOfTopics.map( (thisTopic) => {
                    return (
                        <Nav.Link><Link to={`/news/topics/${thisTopic.replace(' ', '--').toLowerCase()}`}>{ thisTopic }</Link></Nav.Link>
                    )
                })
            }
        </Nav>
    )
}

export default TrendingTopicsNavStripe
