import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Form } from 'react-bootstrap';
import Collapse from 'rc-collapse';
import utils from '../utils/utils';

import 'rc-collapse/assets/index.css';


function FaqStripe({className}) {

    let [ faqList, setFaqList ] = useState();
    let [ filterText, setFilterText ] = useState('');
    let [ loadingFaqList, setLoadingFaqList ] = useState(false);

    const termScore = (question) => {
        try {
            let terms = window.location.href.split("?q=")[1].split("+");
            let score = 0;
            for (const term of terms) {
                if (question.toLowerCase().includes(term.toLowerCase())) {
                    score++;
                }
            }
            return score;
        } catch {
            return 0;
        }
    }

    const getFilteredHelp = (listToFilter, filterText) => {
        if(filterText.length > 0){
            return listToFilter.filter( (thisFAQ) => {
                let filterArr = filterText.split(" ");
                for (const filterWord of filterArr) {
                    if (thisFAQ.fields.body.toLowerCase().indexOf(filterWord.toLowerCase()) === -1
                    && thisFAQ.fields.question.toLowerCase().indexOf(filterWord.toLowerCase()) === -1) {
                        return false;
                    }
                }
                return true;
            })
        } else {
            return listToFilter;
        }
    }

    const setFilteredText = (e) => {
        setFilterText(e.target.value);
    }

    const sortList = (listToSort) => {
        return listToSort.sort( (a, b) => {
            let ascore = termScore(a.fields.question)
            let bscore = termScore(b.fields.question)

            return bscore - ascore;
        })
    }

    const loadFaqList = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=FAQs`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });
        let jsonresponse = await response.json();

        setFaqList(sortList(jsonresponse.items));
    }  

    useEffect( ()=> {
        setLoadingFaqList(true);
        try {
            loadFaqList();
            setLoadingFaqList(false);
        } catch (error) {
            console.log(error); 
            setLoadingFaqList(false);
        }
    // eslint-disable-next-line
    },[])

    return (
        <>
        <Col className="faq-section">
            <Row className="faq-title-line">
                <Col xs={6}>
                    <h4>FAQ</h4>
                </Col>
                <Col xs={6}>
                    <div id="faq-search-header">
                    <Form.Control 
                        type="text"
                        defaultValue={''} 
                        placeholder="Filter Questions"
                        onChange={(e) => {setFilteredText(e)}}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="faq-list-main">
            {
                !loadingFaqList && faqList ?
                    <Collapse accordion={true}>
                        { getFilteredHelp(faqList,filterText).map( (thisFaq, index) => {
                            return (
                            <Collapse.Panel header={thisFaq.fields.question} headerClass="my-header-class" key={index}>
                                <div className="faq-answer" dangerouslySetInnerHTML={utils.getHTML(thisFaq.fields.body)} />
                            </Collapse.Panel>)
                        })}
                    </Collapse>
                : 
                    <Container>
                        <Spinner animation="border"/>
                    </Container>
                    
            }
            </Row>
        </Col>
        </>
    )
}

export default FaqStripe