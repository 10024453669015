import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';

import FullStoryView from '../../components/FullStoryView';

function LbArticlePreviewPage() {

    //this sets up a page to view unpublished articles from agility's preview button
    
    let query = new URLSearchParams(useLocation().search);

    let articleID = query.get("ContentID");

     //get the articles with that topic
    let [ article, setArticle ] = useState();
    let [ loadingArticle, setLoadingArticle ] = useState(true);

    const loadArticle = async (articleContentID) => {
        let response = await fetch(`${process.env.REACT_APP_AGILITY_PREVIEW_URL}/en-us/item/${articleContentID}`, {
            headers: {
              "APIKey": process.env.REACT_APP_AGILITY_PREVIEW_KEY
            }
        });

        let jsonresponse = await response.json();

        setArticle(jsonresponse);
    }
    

    useEffect( ()=> {
        setLoadingArticle(true);
        try {
            loadArticle(articleID);
            setLoadingArticle(false);
        } catch (error) {
            console.log(error);
            setLoadingArticle(false);
        }
    // eslint-disable-next-line    
    },[])

    return (
        !loadingArticle && article ?
            <FullStoryView article={article} />
        : 
            <Container>
                <Spinner animation="border" />
            </Container>
    )
}

export default LbArticlePreviewPage
