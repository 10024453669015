import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Card } from 'react-bootstrap';

import utils from '../utils/utils';

function LBLinkedImageGridStripe({ listToPull, title }) {

    let [ contentsList, setContentsList ] = useState();
    let [ listLoading, setListLoading ] = useState();

    const loadContentsList = async () => {
        
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=${listToPull}`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setContentsList(jsonresponse.items);
    }
    
    useEffect( () => {
        try {
            setListLoading(true);
            loadContentsList();
            setListLoading(false);
        } catch ( error ) {
            console.log(error);
            setListLoading(false);
        }
    // eslint-disable-next-line
    },[]);

    

    return (
        <Row className="linked-image-stripe">
            
                {title ?
                    <h3>{title}</h3>
                : null
                }

                {
                    !listLoading && contentsList ?
                        contentsList.map( (thisCard) => {
                            return (
                                <Col sm={contentsList.length >= 2 ? 6 : 12} md={contentsList.length >= 2 ? 6 : 12} lg={contentsList.length >= 3 ? 3 : contentsList.length >= 2 ? 6 : 12}>

                                <Card className="linked-image-card" >
                                    { 
                                        thisCard.fields.image && thisCard.fields.image.url ?
                                            <a href={thisCard.fields.link ? thisCard.fields.link.href : ''} target="_blank" rel="noreferrer">
                                                <Card.Img variant="top" src={thisCard.fields.image.url} className="linked-image-pic"/>
                                            </a>
                                        : null 
                                    }
                                    {
                                        thisCard.fields.title ? 
                                            <Card.Title>{ thisCard.fields.title }</Card.Title>
                                        :null
                                    }
                                    {
                                        thisCard.fields.notes ? 
                                            <Card.Text dangerouslySetInnerHTML={ utils.getHTML(thisCard.fields.notes)} />
                                        :null
                                    }
                                </Card>
                                </Col>
                            )
                        })

                    : <Spinner animation="border"/>

                }
                {/* {
                    listLoading && !contentsList?
                        <Spinner animation="border"/>
                    :
                        contentsList.map( (thisCard) => {
                            return (
                                <Card className="linked-image-card">
                                    <Card.Text>
                                        dsfdsaf
                                    </Card.Text>
                                </Card>
                            )
                        })
                } */}
           
        </Row>
    )
}

export default LBLinkedImageGridStripe
