import React from 'react';
import { Card, Container, Col} from 'react-bootstrap';
function NoMatch() {
    return (
        <Card className="mx-auto no-match-card">
            <Card.Title>Error 404</Card.Title>
            <Card.Body>
                {
                    <Container >
                        <Col>
                            <h5 style={{textAlign: 'center'}}>Can't find the page you're looking for...<br/>But here's a cute dog!</h5>
                        </Col>
                        <Col>
                            <div style={{ textAlign: 'center' }}>
                                <img src="https://images.pexels.com/photos/5733421/pexels-photo-5733421.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                alt="Could Not Be Loaded"
                                style={{width:"250px", height:"300px"}}
                                title="Photo Credit: Pexels User - Sam Lion">
                                </img>
                                <p>
                                    <a href="https://www.pexels.com/photo/cute-dog-in-medical-clothes-with-stethoscope-5733421/"
                                        target="_blank" rel="noopener noreferrer">
                                        Photo Credit: Pexels User - Sam Lion
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Container>
                }
            </Card.Body>
        </Card>
    )
}

export default NoMatch