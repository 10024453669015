import { CONTENT_TYPES } from './pageConsts';
import Analytics from 'analytics';
import hubSpotPlugin from '@analytics/hubspot'
// import googleAnalytics from '@analytics/google-analytics'


const utils = {

    analytics: null,

    getMonthNames : () => {
        return (["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
    },

    mergeLayoutItemsWithData: (itemArray, news, blocks, stripes) => {
        let refList = blocks;
        let fieldToMatchOn = 'key';
        
        return itemArray.map( (thisItem) => {
            if(thisItem.type === CONTENT_TYPES.STRIPE) {
                refList = stripes;
                fieldToMatchOn = 'key';
            }
            
            let foundItemInList;

            if(thisItem.type === CONTENT_TYPES.NEWS_STRIPE) {
                refList = news;

                //get all the news list and put it in the object
                foundItemInList = null;

            } else {
                foundItemInList = refList.find( (item) => {
                    if(item.fields[fieldToMatchOn] && thisItem[fieldToMatchOn]){
                        return item.fields[fieldToMatchOn].toLowerCase() === thisItem[fieldToMatchOn].toLowerCase();
                    }else{
                        return false;
                    }
                 })
         
                 if(!foundItemInList) {
                     foundItemInList = null
                 }
            }
    
            return { ...thisItem, ...foundItemInList }
        })
    },

    getHTML : (fieldObj) => {
        return { __html: fieldObj }
    },

    convertArrayToObject : ( arrayToConvert ) => {
        let retObj = {};

        arrayToConvert.forEach( (thisItem) => {
            if(thisItem.fields.key) {
                retObj[thisItem.fields.key] = thisItem.fields.htmlvalue;
            }
        });

        return retObj;
    },

    initializeTracking: function(authUsername){
        let hsPlugin = {portalId: '8615684'};
        if(authUsername && authUsername !== '') {
            hsPlugin.name = authUsername;
        }
        
        this.analytics = Analytics({
            app: 'Linksbridge Platform',
            version: 100,
            plugins: [
            //   googleAnalytics({
            //     trackingId: 'UA-128191977-2',
            //   }),
              hubSpotPlugin(hsPlugin)
            ]
          });
    },

    setUserForAnalytics: function(authaccount) {
        if(authaccount) {
            this.analytics.identify({
                name: authaccount.name ? authaccount.name : '',
                email: authaccount.username ? authaccount.username : 'unknown@unknown.com'
              })
        }
    },

    // initializeGA: () => {
    //     ReactGA.initialize('UA-128191977-2');
    // },

    gaPageView: function(pageName){
        // ReactGA.pageview(pageName);
        // this.analytics.page();
    },

    gaEvent: function( label, eventObj ) {
        // ReactGA.event({       
        //     category: eventObj.categoryName,  // Required
        //     action: eventObj.eventName,       // Required
        //     label: eventObj.label,       
        //     ...eventObj     
        // });
        this.analytics.track(label, {
            ...eventObj
        })
    },

    // postEvent: function( event_type, event_val, userid, notes ) {
    //     //we just launch this off - we don't need to check for a response or wait for it.
    //     let postStr = `type_of_interaction=${event_type}&value_of_interaction=${event_val}${ userid ? '&user_identifier='+userid: ''}${ notes ? '&notes='+userid: ''}`;

    //     fetch(`${process.env.REACT_APP_WEB_API}tracking?code=${process.env.REACT_APP_TRACKING_KEY}`, {
    //         method: 'POST',
    //         body: JSON.stringify({ body: postStr })
    //     });
    // },

    formatBodyObj : (queryStr, page, filters, sort, tagQuery) => {
        let retObj = {};

        retObj.query = queryStr;

        if(tagQuery && tagQuery === true) {
            retObj.search_fields = { tags: {} };
            retObj.precision = 6;
        }

        if(page) {
            retObj.page = { current: page };
        }

        retObj.sort = [{'datepublished': 'desc'}];

        return retObj;
    },

    upsert: async function (endPoint, apiKey, data){

        function logError(data, resultJson, message){
            console.log("Data")
            console.log(data)
            console.log("Error")
            console.log(resultJson['detail'])
            alert(message)
        }
        const headers = {
            'Content-Type': 'application/json',
            'access_token': apiKey
        }
        const requestOptions = {
          method: 'POST',
          headers:headers,
          body: JSON.stringify(data)
        }
        let result = await fetch(`${process.env.REACT_APP_DB_API_URL}${endPoint}`, requestOptions)
        let resultJson = await result.json()
        if (result['status'] === 403 ) {
          alert("Invalid API key, access denied")
          return null
        }
        if (result['status'] === 422) {
          // Basic response parsing for known errors, so far only duplicate key errors
          if (resultJson['detail'].includes("duplicate key")){
            logError(data, resultJson, "This record already exists in the database. Please find and update that record.")
            return null
          }
          else if (resultJson['detail'].includes("not allow nulls")){
            logError(data, resultJson,"There is a field in this record that should not be null, check logs for details")
            return null
          }else if (JSON.stringify(resultJson['detail']).includes("is not a valid")){
            logError(data, resultJson,"There is an incorrect type in a field, check logs for details")
            return null
          }else if (JSON.stringify(resultJson['detail']).includes("none is not an allowed value")){
            logError(data, resultJson,"There is a field in this record that should not be null, check logs for details")
            return null
          }
          else{
            // Fall back case. Try to get the user to send the detailed error report.
            logError(data, resultJson,"Unable to create or update for an unknown reason, check logs for details")
            return null
          }
        }
        return resultJson
    },

    getDateString : (dateStr, longFmt) => {
        
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        // cutDate is the whole date, split into parts 2023-03-22
        let cutDate = dateStr.split('T')[0];
        
        // datePcs is cutDate split into an array, using the dash as the delimiter ['2023', '03', '22']
        let datePcs = cutDate.split('-');
        
        // mnthShown uses parseInt function on datePcs, which parses the string at index 1 and converts it to an integer 03
        let mnthShown = parseInt(datePcs[1]);

        // dateShown uses parseInt function on datePcs, which parses the string at index 2 and converts it to an integer 3 
        // this is to remove the zero preceding single digit dates
        let dateShown = parseInt(datePcs[2]);

        if(longFmt && longFmt===true) {
            return `${monthNames[mnthShown-1]} ${dateShown}, ${datePcs[0]}`;
        } 

        return `${datePcs[1]}/${datePcs[2]}/${datePcs[0]}`;
        
    },

    getResultsFromElastic: async (engine, page, thisQuery, numPerPg, sortOrder = null, filters = null, start = null, end = null, tagQuery = false) => {
        let searchObj = {
            engine: engine,
            is_boolean: tagQuery && tagQuery === true,
            filters: filters ? filters : {}, 
            size: numPerPg,
            page: page ? page : 0,
            start_date: start ? start : null,
            end_date: end ? end : null,
            sort_order: sortOrder ? sortOrder : 'DATE_DESC',
            tenant_id: process.env.REACT_APP_PUBLIC_TENANT_ID
        } 

        if (engine === 'linksbridge') {
            searchObj.exclude_filters = {'category': ['MLPNormal', 'MLPReport']};
        }

        if(tagQuery && tagQuery === true && thisQuery.length > 0 ) {
            searchObj.tags = decodeURIComponent(thisQuery).split(',');
        } else {
            searchObj.query = thisQuery;    
        }
    
        return await utils.upsert('lb_search', process.env.REACT_APP_DB_PUBLIC_KEY, searchObj);
    },
    

    addTargetToLinksInHtml: function(linkFields) { 
        //this function takes our "links" field from agility, and puts a 'target="_blank" in each
        return linkFields.replace(/<a href=/g, '<a target="_blank" rel="noreferrer" href=');
    },

    //this function takes in a url and looks to see if the template link exists and if so replaces it with the right url
    removeURLPlaceholder: (link, type) => {
        if (type === 'news') {
           return link.replace(/{{news_page_link}}/g, 'linksbridge.com/news/')
        } 
        if (type === 'topics') {
            return link.replace(/{{topic_page_link}}/g, 'linksbridge.com/topics/')
        }
    },

    // tagTracker: async (tagToSend) => {
    //     await fetch(`https://gvmmapi.azurewebsites.net/api/tag-tracking?code=Cw1Pz0rpjSD/Woa9ZSCdqdMYqmQIDPGNDc3StP9OitHyPoHad7mVLw==&tag=${tagToSend}`);
    // },

    convertElasticArticleToNewsArticle: (eArticles) => {

        return eArticles.map( ( thisArticle ) => {
           
            return {
                contentID : thisArticle.contentid.raw,
                fields: {
                    datepublished: thisArticle.datepublished.raw,
                    title: thisArticle.title.raw,
                    body: thisArticle.body.raw,
                    articlePreviewText: thisArticle.preview_text ? thisArticle.preview_text.raw: "",
                    tags: thisArticle.tags ? thisArticle.tags.raw : []
                }
            }
        });
    },

}

export default utils