import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Spinner, Card } from 'react-bootstrap';
import Pagination from 'rc-pagination';
import localeInfo from '../../utils/locale_en_US';

import NewsTeasers  from '../../components/NewsTeasers';

import utils from '../../utils/utils';

function LbNewsTopicsPage({onTotalPagesReceived}) {

    //get the articles with that topic
    let [ articles, setArticles ] = useState();
    let [ loadingArticles, setLoadingArticles ] = useState(true);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ numPages, setNumPages ] = useState(1);

    const NUM_PER_PAGE = 20;
    let tagQuery;

    const { topicname } = useParams();

    const getQueryString = () => {

        tagQuery = window.location.search
        if(tagQuery) {
            tagQuery = tagQuery.split('=')
            tagQuery = tagQuery.includes('true') ? true : false;
        } else {
            tagQuery = false;
        }
    }

    const loadArticles = async (loadPage) => {
        
        let response = await utils.getResultsFromElastic('linksbridge', loadPage, topicname, NUM_PER_PAGE, null, null, null, null, tagQuery)

        let numberOfPages = response.hit_count/NUM_PER_PAGE;
        setNumPages(numberOfPages);
        onTotalPagesReceived(numberOfPages);
        
        setArticles(response.results);
    }

    const handlePageClick = (pageNum) => {
        setCurrentPage(pageNum);
    }
    

    useEffect( ()=> {
        setLoadingArticles(true);
        // utils.postEvent('VIEWED_TOPIC', topicname, username, null);
        // utils.tagTracker(topicname);
        try {
            getQueryString()
            loadArticles(currentPage);
            setLoadingArticles(false);
        } catch (error) {
            console.log(error);
            setLoadingArticles(false);
        }
    // eslint-disable-next-line    
    },[topicname, currentPage])

    return (
        <Row className="content-stripe-row news-topic-stripe">
            {
                !loadingArticles && articles ?
                    <Col>
                        <Row>
                            <Col xs={12}>
                                <Card className="topic-news-list-card">
                                    <Card.Title><h4>Explore Topic: <span dangerouslySetInnerHTML = {utils.getHTML(topicname.replace('--', ' '))}></span> - Page {currentPage}</h4></Card.Title>
                                    <NewsTeasers 
                                        className="news-topic-list" 
                                        urlprefix={'/news/'}
                                        stories={articles} 
                                        showNumber={NUM_PER_PAGE} 
                                        loadingTracker={loadingArticles} 
                                        showTags />
                                    <div className="text-center">
                                        { 
                                            numPages && numPages > 0 ?
                                                <Pagination 
                                                    total={(numPages*NUM_PER_PAGE)}
                                                    onChange={ (item) => handlePageClick(item)}
                                                    current={currentPage} 
                                                    defaultPageSize={ NUM_PER_PAGE } 
                                                    pageSize={NUM_PER_PAGE}
                                                    locale={localeInfo} />
                                            : null
                                        }
                                    </div>
                                </Card>
                            </Col>
                            <Col>
                                {/* related topics */}
                            </Col>
                        </Row>
                    </Col>
                : <Spinner animation="border"/>
            }
        </Row>
    )
}

export default LbNewsTopicsPage
