import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Form, Button } from 'react-bootstrap';
import 'rc-collapse/assets/index.css';


function EditStripeHubSpot() {

    let [ editList, setEditList ] = useState();
    let [ loadingEditList, setLoadingEditList ] = useState(false);
    let [ hoursNum, setHoursNum ] = useState(24);

    const loadEditList = async () => {
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?formail=${hoursNum}&category=PharmaNormal&contenttype=articles`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_MAILING_KEY
            }
        });
        let jsonresponse = await response.json();

        sortNewsStickyWeight(jsonresponse.items);
    }
    
    const sortNewsStickyWeight = (jsonList) => {
        jsonList.forEach((item) => {
            if(!item.fields.stickyweight) {
                item.fields.stickyweight = 0;
            }
        })
        
        jsonList.sort(function(a, b) {
            
            return a.fields.stickyweight - b.fields.stickyweight; 
        })
        return setEditList(jsonList);
    }

    const addLinkStyle = (linkFields) => {
        if(linkFields && linkFields.length > 0) {
            return linkFields.replace(/<ul>/g, '').replace(/<\/ul>/g, '').replace(/<li/g, '<p').replace(/<\/li>/g, '</p>').replace(/<a/g, '<a style="color:#007C89; text-decoration:none;"').replace(/{{news_page_link}}/g, 'linksbridge.com/news/').replace(/{{topic_page_link}}/g, 'linksbridge.com/topics/');
        }
    }

    const addLinkColor = (storyBody) => {
        let tempVar;
      
        tempVar = storyBody.replaceAll('<a', '<a style="color:#007C89;"');
        return tempVar;
    }

    const addParagraphBreak  = (linkFields) => {
        if(linkFields && linkFields.length > 0) {
            return linkFields.replace(/<\/p>/g, '</p><br/>');
        }
    }

    useEffect( ()=> {
        setLoadingEditList(true);
        try {
            loadEditList();
            setLoadingEditList(false);
        } catch (error) {
            console.log(error); 
            setLoadingEditList(false);
        }
    // eslint-disable-next-line
    },[hoursNum])



    const elemTags = (elemName) => {
       return String.fromCharCode(60) + (elemName) + String.fromCharCode(62);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setHoursNum(e.target.elements.hours.value);
    }

    return (
        <>
        <Form onSubmit={handleSubmit}>
            <Form.Label>
                Number of hours to look back
            </Form.Label>
            <input name="hours" type="number" defaultValue={hoursNum} style={{margin: '15px'}}>
            </input>
            <Button type="submit">Submit</Button>
        </Form>
        <br /><br />
        <Col className="edit-section">
            <Row className="edit-list-main">
            {
                !loadingEditList && editList ?
                <code>
                    <p style={{maxWidth: 1000}}>
                        <br/> <br/>
                        {elemTags('span style=line-height:125%')}
                        {elemTags('strong')}New this week{elemTags('span data-ccp-parastyle="No Spacing"')}:{elemTags('/span')}{elemTags('/strong')}&nbsp;
                        {
                            editList.map((thisStory, index) => {
                                const linkTag = thisStory.fields.title.split(" ").slice(0,3).join("_");
                                return (
                                    <>
                                        <>
                                        {elemTags(`a href="#_${linkTag}" style= "color:#007C89; text-decoration:none"`)}{thisStory.fields.title}{index === editList.length -1 ? null : ""}{elemTags('/a')}
                                        </>
                                        {index === editList.length -1 ? null : ";"}
                                        <br/>
                                    </>
                                )
                            })
                        } <br/>
                        {elemTags('br /')}{elemTags('br /')}<br/>
                        {elemTags('hr /')}<br/><br/>
                        {
                            editList.map((thisStory, index) => {
                                const linkTag = thisStory.fields.title.split(" ").slice(0,3).join("_");
                                let tagList = [];
                                thisStory.fields.tags.map((thisTag) => {
                                    return (
                                        tagList.push(thisTag.fields.tagtext)
                                    )
                                })
                                return (
                                    <>
                                        <>
                                            {elemTags(`a name="_${linkTag}"`)} {elemTags('/a')}{elemTags('span style="color:#000000"')}{elemTags('strong style="color:#000000"')}
                                            {thisStory.fields.title}{elemTags('/strong')}{elemTags('/span')}{elemTags('br /')}&nbsp;{elemTags('br /')}
                                            {addParagraphBreak(addLinkColor(thisStory.fields.body))}
                                            <br/>
                                            PRIOR COVERAGE:{elemTags('br /')}{elemTags('br /')}
                                              {thisStory.fields.links ? addLinkStyle(thisStory.fields.links) : null}                      
                                        </>
                                        {elemTags('br /')}
                                        {index === editList.length -1 ? null : elemTags('hr /')}
                                    </>
                                )
                            })
                        }[ends]&nbsp;
                        {elemTags('/span')}
                    </p>
                    </code>
                : 
                    <Container>
                        <Spinner animation="border"/>
                    </Container>
                    
            }
            </Row>
        </Col>
        </>
    )
}

export default EditStripeHubSpot;