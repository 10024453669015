import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Card } from 'react-bootstrap';
import NewsTeasers from '../../components/NewsTeasers';
import Pagination from 'rc-pagination';
import localeInfo from '../../utils/locale_en_US';
import utils from '../../utils/utils';
import NewsSortDropdown from '../../components/NewsSortDropdown';
import SearchResultsFeatureWrapper from '../../components/SearchResultsFeatureWrapper';
import SearchResultsNodeWrapper from '../../components/SearchResultsNodeWrapper';



function SearchResultsPage({ term, onTotalPagesReceived }) {

    let [ nodeResults, setNodeResults ] = useState();
    let [ featurePages, setFeaturePages ] = useState();
    let [ results, setResults ] = useState();
    let [ sessionID, setSessionID ] = useState();
    let [ currentTerm, setCurrentTerm ] = useState();
    let [ loading, setLoading ] = useState(true);
    let [ currentPage, setCurrentPage ] = useState(1);
    const [sortChoice, setSortChoice] = useState('Most Recent')
    let [ numOfPages, setNumOfPages ] = useState(1);

    const NUM_PER_PAGE = 20;

    const handlePageClick = (num) => {
        setCurrentPage(num);
    }
          
    const SORTOBJ = {
        'relevance': 'Relevance',
        'most-recent-date': 'Most Recent',
        'oldest-date': 'Oldest'
    }

    const getSelectedFeature = (featureList, slug) => {
        let picked = featureList.filter( (thisItem) => {
            if(thisItem.fields && thisItem.fields.tags_TextField) {
                let tagsList = thisItem.fields.tags_TextField.toLowerCase().split(',')
                return tagsList.includes(slug.toLowerCase());
            } else {
                return undefined;
            }
        });
        return picked;   
    }

    // getFeatureTopics from agility
    const loadFeatureTopic = async () => {
        setLoading(true)
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=FeaturePage`, {
          headers: {
            "x-functions-key": process.env.REACT_APP_GVMM_KEY
          }
      });
    
      let jsonresponse = await response.json();
      let featuredTopic = getSelectedFeature(jsonresponse.items, term)
      setFeaturePages(featuredTopic)
      setLoading(false)
    }
    
    // getTopicNodes gets nodes from ElasticSearch
    const getTopicNodes = async (page, term) => {

        setLoading(true) ; 

        let response = await utils.upsert('lb_search', process.env.REACT_APP_DB_PUBLIC_KEY,{
            tags: [term],
            engine:'topic-nodes',
            is_boolean:false,
            filters:{},
            size:10,
            page:page ? page : 0,
            sort_order:'SCORE'
        }) 

        if(response.meta && response.meta.request_id) {
            setSessionID(response.meta.request_id);
        }

        setNodeResults(response.results)
        setLoading(false)
    }

    const getResults = async (engine, page, thisQuery, sort) => {
        setLoading(true); 
        let sortOrder = sort ? sort : 'DATE_DESC';
        let filters = {};
    
        let response = await utils.getResultsFromElastic(engine, page, thisQuery, NUM_PER_PAGE, sortOrder, filters);
    
        let numberOfPages = response.hit_count / NUM_PER_PAGE;
        setNumOfPages(numberOfPages);
        onTotalPagesReceived(numberOfPages);
        setResults(response.results);
        setLoading(false)
    }

    

   // sortHandler configures the Sort By Dropdown 
    const sortHandler = (sortValue) => {
        setSortChoice(SORTOBJ[sortValue])
        let sort 
        if(sortValue === 'relevance') {
            sort = 'SCORE';
        }
        else if (sortValue === 'most-recent-date') {
            sort = 'DATE_DESC';
        }
        else if(sortValue === 'oldest-date') {
            sort = 'DATE_ASC'
        } 

        getResults('linksbridge', currentPage, term, sort)
    }


    useEffect( ()=> {
        if(term && term.length > 0){
            if(term !== currentTerm) {
                setCurrentTerm(term);
                setCurrentPage(1);
            }
            try {
                setLoading(true);
                setCurrentTerm(term);
                setSortChoice('Most Recent')
                loadFeatureTopic(currentPage)
                getTopicNodes(currentPage, term);
                getResults('linksbridge', currentPage, term);    
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, term]);


    return (
        !loading ?
            <Container className="margin-at-top news-topic-stripe search-results-news">
                    <>
                    {
                        (nodeResults && nodeResults.length > 0) || (featurePages && featurePages.length > 0) ?
                            <Row className='curated-topics-row'>
                                {
                                    nodeResults && nodeResults.length > 0 ?
                                        <Col>
                                            <SearchResultsNodeWrapper elasticInput={nodeResults} />  
                                        </Col>
                                    : ''
                                }
                                {
                                    featurePages && featurePages.length > 0 ?
                                        <Col>
                                            <SearchResultsFeatureWrapper featurePageInput={featurePages} />  
                                        </Col>
                                    :''
                                }
                            </Row>
                        : ''
                    }
                    {
                        results ?
                            <Row>
                                <Col xs={12}>
                                    <Card className="topic-news-list-card search-results-news-list">
                                        <Card.Title><h3 className="search-results">Search Results: { term } - Page {currentPage}</h3></Card.Title>
                                        <NewsSortDropdown
                                            className="news-sort-dropdown"
                                            sortHandler={sortHandler}
                                            sortChoice={sortChoice}
                                        />
                                        {
                                            !loading ?
                                            <>
                                                <NewsTeasers 
                                                    className="news-topic-list search-results-news-teasers" 
                                                    urlprefix={'/news/'}
                                                    stories={results} 
                                                    showNumber={NUM_PER_PAGE} 
                                                    loadingTracker={loading} 
                                                    showTags 
                                                    trackingInfo={`${sessionID}|||${term}`} />
                                                <div style={{textAlign: 'center'}}>
                                                    <Pagination 
                                                        total={numOfPages * NUM_PER_PAGE}
                                                        onChange={ (item) => handlePageClick(item)}
                                                        current={currentPage}
                                                        defaultPageSize={ NUM_PER_PAGE } 
                                                        pageSize={ NUM_PER_PAGE }
                                                        locale={localeInfo} />
                                                </div> 
                                            </>
                                            :
                                            term && term.length > 0 ?
                                            <>
                                                <div style={{width: '100%', textAlign: 'center'}}>
                                                    <Spinner animation="border"  variant="warning"/> Retrieving Search Results 
                                                </div>
                                            </>
                                        :
                                        <div style={{width: '100%', textAlign: 'center'}}>
                                            Please enter a search term in the box above.
                                        </div> 
                                        }
                                    </Card>
                                </Col>
                                    
                            </Row>
                        : ''
                    }
                </>
            </Container>
        : <Spinner animation='border' />
    )
}


export default SearchResultsPage
