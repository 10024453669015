import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Spinner, Card } from 'react-bootstrap';
import NewsTeasers from '../../components/NewsTeasers';
import Pagination from 'rc-pagination';
import localeInfo from '../../utils/locale_en_US';
import utils from '../../utils/utils';

function SearchResultsPage({onTotalPagesReceived}) {

    let [ results, setResults ] = useState();
    let [ loading, setLoading ] = useState(true);
    let [ currentPage, setCurrentPage ] = useState(1);
    let [ numPages, setNumPages ] = useState(1);

    const NUM_PER_PAGE = 35;

    const handlePageClick = (num) => {
        setCurrentPage(num);
    }

    const getResults = async (loadPage) => {
        setLoading(true)
        let response = await utils.getResultsFromElastic('linksbridge', loadPage, '', NUM_PER_PAGE)
       
        if(response.meta && response.meta.page && response.meta.page.total_pages){
            setNumPages(response.meta.page.total_pages)
        }

        let numberOfPages = response.hit_count/NUM_PER_PAGE;
        setNumPages(numberOfPages);
        onTotalPagesReceived(numberOfPages);
        
        setResults(response.results);
        setLoading(false)
    }

    useEffect( ()=> {     
      try {
          setLoading(true);
          getResults(currentPage);    
          setLoading(false);
      } catch (error) {
          console.log(error);
          setLoading(false);
      }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    return (
        <Container className="margin-at-top news-topic-stripe">
            {
                !loading && results ?
                    <Row>
                        <Col xs={12}>
                            <Card className="topic-news-list-card">
                                <Card.Title><h4>Latest News - Page {currentPage}</h4></Card.Title>
                                <NewsTeasers 
                                    className="news-topic-list" 
                                    urlprefix={'/news/'}
                                    stories={results} 
                                    showNumber={NUM_PER_PAGE} 
                                    loadingTracker={loading} 
                                    showTags />
                                <div style={{textAlign: 'center'}}>
                                    <Pagination 
                                        total={numPages * NUM_PER_PAGE}
                                        onChange={ (item) => handlePageClick(item)}
                                        current={currentPage}
                                        defaultPageSize={ NUM_PER_PAGE } 
                                        pageSize={ NUM_PER_PAGE }
                                        locale={localeInfo} />
                                </div>
                            </Card>
                        </Col>
                            
                    </Row>
                : <Spinner animation='border' />
                
            }
        </Container>
    )
}

export default SearchResultsPage
