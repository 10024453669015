import React from 'react';

import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbOurWorkPage({ stripes }) {

    const pageLayout = [
        // { 
        //     contents: [{
        //         type: CONTENT_TYPES.STRIPE,
        //         name: 'Our Work Title',
        //         className: 'our-work-title',
        //         key: 'our-work-title'
        //     }]
        // },
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Our Work Title Large',
                className: 'our-work-title-large',
                key: 'our-work-title-large'
            }]
        },
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Our Work Hero Banner',
                className: 'our-work-title-small',
                key: 'our-work-hero-banner'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Global Health Assets',
                    className: 'our-work-high-level-card',
                    key: 'our-work-global-health-assets'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Public Information',
                    className: 'our-work-high-level-card',
                    key: 'our-work-public-information'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Consulting and Project Services',
                    className: 'our-work-high-level-card',
                    key: 'our-work-consulting-and-project-services'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Highlighted Works Title',
                    className: 'our-work-highlighted-works-title',
                    key: 'our-work-highlighted-works-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.PROJECTS_LIST,
                    name: 'Our Work Featured Projects',
                    className: 'projects-list'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Work With Us',
                    className: 'work-with-us',
                    key: 'work-with-us'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Additional Resources Title',
                    className: 'our-work-additional-resources-title',
                    key: 'our-work-additional-resources-title'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Additional Resources GVMM Help and FAQ',
                    className: 'our-work-gvmm-help-faq',
                    key: 'our-work-gvmm-help-faq'
                },
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Our Work Additional Resources The Linksbridge Method',
                    className: 'our-work-linksbridge-method',
                    key: 'our-work-linksbridge-method'
                }
            ]
        }

    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='our-work-page'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbOurWorkPage
