import React from 'react';
import { Row, Card, Col } from 'react-bootstrap';


// "small-repeated-value" is the className of 6 of the 7 LBValues. "Bring Your Whole Self" is className "full-width-value"

function RepeatedSmallItemStripe({contentList, className}) {

    return (
        <Row className={`${className ? className: ''} content-small-item-stripe-row`}>
            {
                contentList ?
                    contentList.map( (thisItem) => {
                        return (
                            <>
                            { thisItem.fields.className && thisItem.fields.className === "small-repeated-value" ?
                                <Col  md={12} lg={12}>
                                    <Card>
                                        <Card.Title>
                                            { thisItem.fields.title ? thisItem.fields.title : null }
                                        </Card.Title>
                                        <Card.Body>
                                        {
                                            thisItem.fields.body ? thisItem.fields.body : null
                                        }
                                        </Card.Body>
                                    </Card>   
                                </Col>
                            : null }
                            </>
                        )
                    })
                : null
            }
        </Row>
    )
}

export default RepeatedSmallItemStripe
