import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import NewsTeasers from './NewsTeasers';
import utils from '../utils/utils';

function NewsExploreBox({ className, exploreConfig, tagQuery=false }) {
    //exploreConfig - { topics: 'topic1,topic2,...' }

    let [ exploreArticleList, setExploreArticleList ] = useState();
    let [ loadingList, setLoadingList ] = useState(true);

    //if no topics passed, default to "vaccine markets" and "access to medicines"
    const topicsToShow = exploreConfig && exploreConfig.result ? exploreConfig.result : 'vaccine markets,access to medicines';

    const loadExploreArticles = async () => {
        let returnArray = [];

        //for each topic, request from elastic
        let topicArray = topicsToShow.trim().split(',');
        let curCounter = 0;
        
        while (curCounter < topicArray.length ) {
            let articlesResults = await utils.getResultsFromElastic("linksbridge", 1, topicArray[curCounter], 3)

            returnArray.push({ topic: topicArray[curCounter], articles : articlesResults.results});

            curCounter++;
        }

        setExploreArticleList(returnArray);
    }

    useEffect( ()=> {
        setLoadingList(true);
        try {
            loadExploreArticles();
            setLoadingList(false);
        } catch (error) {
            console.log(error);
            setLoadingList(false);
        }
    // eslint-disable-next-line    
    },[])


    return (
        <Row>
            <Col>
                <Card className={ className + " news-explore-box" }>
                    <Card.Body>
                        <Row>
                    {
                        !loadingList && exploreArticleList  ?
                            exploreArticleList.map( (thisTopic, index) => {
                                return (
                                    <Col key={index} className="topic-section" sm={12} lg={6}>
                                        <h2 className="inline-title">{ thisTopic.topic }</h2>
                                        <NewsTeasers 
                                            className="news-explore-topic-list" 
                                            urlprefix={'/news/'}
                                            stories={thisTopic.articles} 
                                            showNumber={4} 
                                            loadingTracker={loadingList} />
                                        <div className='text-center'>
                                            <Link className="view-all" to={`/news/topics/${thisTopic.topic.replace(' ','--')}`}>View All Articles</Link>
                                        </div>
                                    </Col>
                                )
                            })
                        :
                        <Spinner animation="border"/>
                    }
                    </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default NewsExploreBox
