import React from 'react'
import { Container, Row, Image, Col } from 'react-bootstrap'
import TagDisplay from './TagDisplay'
import utils from '../utils/utils'

function FeaturePageDisplay({featureToDisplay, preview=false}) {
  return (
    featureToDisplay && !preview ?
      <Container className='feature-pg-container'>
        <Container className='banner-img-container'>
          <Row>
            <Col className='header-content'>
              <h5>{utils.getDateString(featureToDisplay.datePublished, true)}</h5>
              <h1>{featureToDisplay.pageTitle}</h1>
              {
                featureToDisplay.authorName && featureToDisplay.showAuthor === 'true' ?
                <h5>{`By ${featureToDisplay.authorName}`}</h5>
                : ''
              }
            </Col>
            {
              featureToDisplay.bannerImage && featureToDisplay.bannerImage.url ?
                <Col>
                  <Image className='banner-image' src={featureToDisplay.bannerImage.url}/> 
                </Col>
              : ''
            }
          </Row>
        </Container>

        <Container className='main-content'>
          <p 
            className='feature-page-content'
            dangerouslySetInnerHTML={{__html: featureToDisplay.mainContent}}
          ></p>
          <TagDisplay tagList={featureToDisplay.tags ? featureToDisplay.tags : ''} />
        </Container>
      </Container>
    : 
    <Container className='prev-feature-pg-container' >
      <Row>
        <Image src={featureToDisplay.bannerImage.url} className="banner-image"/> 
        <h5 className='h5 preview-feature-title'>{featureToDisplay.pageTitle}</h5>
        <p className='body-medium sm-feature-pre-text'>{featureToDisplay.articlePreviewText}</p>
        <span className='pre-feature-date'>{utils.getDateString(featureToDisplay.datePublished, true)}</span>
      </Row>
    </Container>

  )
}

export default FeaturePageDisplay