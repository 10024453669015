import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TopicNodeDisplay from './TopicNodeDisplay';


// this component takes the Topic Nodes from Elastic Search, and formats the results.
// nested within this component is TopicNodeDisplay which displays the results
// styling for this component is at _newsTopicNodes.scss

function SearchResultsNodeWrapper({elasticInput}) {

const getTopicNodeFormat = (listItem) => {
  
    return (
        {
            pageUrl: listItem.fields.page_url,
            pageTitle: listItem.fields.page_title,
            articlePreviewText: listItem.fields.article_preview_text,
            mainContent: listItem.fields.main_content,
            topicTags: listItem.fields.tags
        }
        
    );
}

   return (
    elasticInput && elasticInput.length > 0 ? 

        <Row>
            <Container className="search-results-topic-nodes-stripe">
        
            {elasticInput.map((item, index) => {
       
            return (
                index < 2 ? 
                    <Row>
                        <Col>
                            <Card className="news-topic-node-preview">
                                <Link to={`/topic/${item.fields.page_url}`}>
                                    <TopicNodeDisplay 
                                    topicToDisplay={getTopicNodeFormat(item)}/> 
                                </Link>
                            </Card> 
                        </Col>
                    </Row>
                : ""
            )
    })}
        </Container>
    </Row>
    : ""
    )
}
 export default SearchResultsNodeWrapper