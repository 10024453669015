import React from 'react';
import TopicNodeDisplay from '../components/TopicNodeDisplay';

// this component formats topic node search results from Agility
// TopicNodeDisplay displays them

function TopicNodePageWrapper({rawTopicNodeObj}) {

    const adaptInput = {
        pageTitle: rawTopicNodeObj.fields.pageTitle,
        mainContent: rawTopicNodeObj.fields.mainContent,
        articlePreviewText: rawTopicNodeObj.fields.articlePreviewText,
        tags: rawTopicNodeObj.fields.tags
    }

   return (
        <div>
            <TopicNodeDisplay 
                topicToDisplay={adaptInput}
            />
        </div>
   );
 }

 export default TopicNodePageWrapper