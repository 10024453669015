import React from 'react';
import { Container,Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import LBContactUsComp from './LBContactUsComp';

function LBMainFooter({ contentItems }) {;
    
    return (
        <Container className="footer-container">
            <Row>
                <Col sm={12} md={12} lg={4} xl={4} xxl={4} className="footer-first-column">
                <h5 className="footer-heading">We work on issues that matter.</h5>
                <p>&copy; Linksbridge SPC 2008 - {new Date().getFullYear()} </p>
                    <Image
                        id="footer-lb-icon" 
                        src="/linksbridge-icon.png" 
                        alt="Linksbridge SPC, Seattle WA" 
                        width="40" />
                </Col>



                <Col sm={12} md={6} lg={4} xl={4} xxl={4} className="footer-second-column">
                    <h5 className="footer-heading">Explore</h5>
                    <ul className="footer-link-list">
                        <li>
                            <Link to={'/'}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to={'/ecosystem'}>
                                Our Ecosystem
                            </Link>
                        </li>
                        <li>
                            <Link to={'/news'}>
                                News
                            </Link>
                        </li>
                        <li>
                            <Link to={'/data-use'}>
                                Data Use
                            </Link>
                        </li>
                        
                        <li>
                            <Link to={'/about-us'}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to={'/privacy-policy'}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to={'/our-work'}>
                                Our Work
                            </Link>
                        </li>
                        <li>
                            <Link to={'/help'}>
                                Help & FAQ
                            </Link>
                        </li>  
                        <li>
                            <Link to={'/careers'}>
                                Careers
                            </Link>
                        </li>
                    </ul>
                </Col>

                <Col sm={12} md={6} lg={4} xl={4} xxl={4} className="footer-third-column">
                    <LBContactUsComp
                        className="footer-contact" 
                        headText={ contentItems ? contentItems['footer-header-text'] : ''} />
                </Col>
            </Row> 
        </Container>
    )
}

export default LBMainFooter
