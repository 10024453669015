import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbPrivacyPolicyPage({ stripes }) {
    
    const pageLayout = [
        {
            contents: [{
                type: CONTENT_TYPES.POLICIES,
                name: 'Privacy Policy',
                className: 'privacy-policy'
            }]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='data-use-page'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbPrivacyPolicyPage