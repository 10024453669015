import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'


function NewsSortDropdown({sortHandler, sortChoice}) {
     const SORT_BY_RELEVANCE = [

            {
                label: 'Relevance',
                value: 'relevance'
            },

            {
                label: 'Most Recent',
                value: 'most-recent-date'
            },

            {
                label: 'Oldest',
                value: 'oldest-date'
            }
        ]


    const eventHandler = (sortValue) => {
        sortHandler(sortValue); 
    }

    return (
        <div className="news-sort-dropdown">
            <span>Sort By:</span>
            <Dropdown
                name={'Sort By:'}
                onSelect={(e) => eventHandler(e)} 
            > 
                <Dropdown.Toggle className="text-left dropdown-toggle btn btn-primary">
                    {sortChoice}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                { 
                    SORT_BY_RELEVANCE.map((thisItem) => { 
                        return ( 
                            <Dropdown.Item 
                                eventKey={thisItem.value}>
                                {thisItem.label}
                            </Dropdown.Item>
                        )
                    })
                }  
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default NewsSortDropdown
