import React, {useEffect, useState} from 'react';
import { Spinner, Container } from 'react-bootstrap';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function HomePage({ blocks, stripes }) {

    let [news, setNews] = useState();
    let [loadingNews, setLoadingNews] = useState(true);

    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.TEASER,
                name: 'We work on issues that matter.',
                key: 'home-hero-banner',
                className: 'hero-banner'

            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.TEASER,
                    title: 'Unparalleled Data',
                    key: 'unparalleled-data-card',
                    className: 'home-highlevel-card'
                },
                {
                    type: CONTENT_TYPES.TEASER,
                    title: 'Impactful Insights',
                    key: 'impactful-insights-card',
                    className: 'home-highlevel-card'
                },
                {
                    type: CONTENT_TYPES.TEASER,
                    title: 'Creative Solutions',
                    key: 'creative-solutions-card',
                    className: 'home-highlevel-card'
                },
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    title: 'Home Service and Product Offerings',
                    key: 'home-service-product-offerings',
                    className: 'home-service-product-offerings'
                }
            ]
        },
        { 
            contents: [
                {
                    type: CONTENT_TYPES.TEASER,
                    title: 'State of Campaigns, Volume 4',
                    key: 'state-of-campaigns-2022',
                    className: 'home-left-card'
                },
                {
                    type: CONTENT_TYPES.TEASER,
                    title: 'Digital Solutions',
                    key: 'home-ecosystem',
                    className: 'home-right-card'
                },
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.NEWS_STRIPE,
                    title: 'News',
                    className: 'news-stripe-row'
                }
            ]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    title: 'Home Be A Part Of Our Mission',
                    className: 'home-be-a-part',
                    key: 'home-be-a-part'
                }
            ]
        },
    ];


    const loadNews = async () => {
        let response = await utils.getResultsFromElastic('linksbridge', 1, '', 10)
        setNews(response.results);
    }

    useEffect( ()=> {
        setLoadingNews(true);
        try {
            loadNews();
            setLoadingNews(false);
        } catch (error) {
            console.log(error);
            setLoadingNews(false);
        }
    },[])


    let mergedContent = [];

    if(blocks && stripes && news ){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, news, blocks, stripes);
        })
    }

    return (
        <div className="home-page">
            { 
                blocks && stripes ?
                    <LBPageComponent 
                        mergedContent={mergedContent} 
                        optionalDepContent={{loadingNews : loadingNews, news: news}} 
                        />
                : <Container style={{margin: "auto", textAlign: "center"}}>
                    <Spinner animation="border"/>
                  </Container>
            }
        </div>
    )
}

export default HomePage
