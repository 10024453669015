import React from 'react';
import { Row } from 'react-bootstrap';
import NoMatch from './NoMatch';

function NoMatchStripe() {
    return (
        <Row className="content-stripe-row"> 
            <NoMatch />
        </Row>
    )
}

export default NoMatchStripe