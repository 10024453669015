
import React, {useEffect, useState} from 'react';
import FeaturePageWrapper from './FeaturePageWrapper';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function FeaturePagePage({preview=false}) {
  let [loading, setLoading] = useState(true);
  let [featureTopic, setFeatureTopic] = useState();

  const { featureSlug } = useParams();

  const getSelectedFeature = (featureList, slug) => {
    let picked = featureList.filter( (thisItem) => {
        return thisItem.fields.pageKeyURL.toLowerCase() === slug.toLowerCase();
    });

    if(picked.length > 0) {
        return picked[0];
    }
    return null;   
}

  const loadFeatureTopic = async (featureToPick) => {
    let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=FeaturePage`, {
      headers: {
        "x-functions-key": process.env.REACT_APP_GVMM_KEY
      }
  });

  let jsonresponse = await response.json();
  let theFeature = getSelectedFeature(jsonresponse.items, featureToPick)
  setFeatureTopic(theFeature);
  }

  useEffect(()=> {
    try {
      if (loading === true && featureSlug) {
        loadFeatureTopic(featureSlug);
        setLoading(false);
      }
    } catch (error) {
        console.log(error)
        setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, featureSlug])

  return (
    !loading && featureTopic ?

    <div>
      <FeaturePageWrapper
        rawFeatureTopic={featureTopic}
        preview={preview}
      />
    </div>
    :
    <Spinner animation='border' />
  )
}

export default FeaturePagePage