import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Facebook, Twitter, Linkedin } from 'react-bootstrap-icons';

import utils from '../utils/utils';

function LBContactUsComp({ className, headText, signedUp, onSignUp }) {
    return (
        <Container className={(className ? className : '')+' contact-us-box'}>
            <Row>
                <Col >
                    {
                        headText ? <div dangerouslySetInnerHTML={utils.getHTML(headText)}></div> : null
                    }
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <NewsletterSignUpForm signedUp={signedUp} onSignUp={onSignUp? onSignUp: null}/>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="social-media-links-wrapper">
                        <Container>
                            <Row className="footer-social-icons">
                                <div className="social-media-link first">
                                    <a href="https://www.facebook.com/linksbridge/" target="_blank" rel="noreferrer">
                                    <Facebook size={25} className='social-media-icon'/> </a>
                                </div>
                                <div className="social-media-link second">
                                    <a href="https://twitter.com/linksbridge" target="_blank" rel="noreferrer">
                                    <Twitter size={30} className='social-media-icon' /></a>
                                </div>
                                <div className="social-media-link third">
                                    <a href="https://www.linkedin.com/company/linksbridge" target="_blank" rel="noreferrer">
                                    <Linkedin size={29} className='social-media-icon'/></a>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LBContactUsComp
