import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// import { BoxArrowInRight, PersonCheckFill } from 'react-bootstrap-icons'
// import { useIsAuthenticated } from '@azure/msal-react';
// import microsoftLogo from '../../assets/images/microsoft-logo.png';

import FullStoryView from '../../components/FullStoryView';
// import SigninLink from '../../components/SigninLink';
// import ReusableModal from '../../components/ReusableModal';

function LbNewsArticlePage() {

    //get the articles with that topic
    let [ article, setArticle ] = useState();
    let [ loadingArticle, setLoadingArticle ] = useState(true);
    //shows first modal when user isn't signed in and has read 3 articles
    // let [ show, setShow ] = useState(false);
    //shows create account modal when user selects create account
    // let [ showCreate, setShowCreate ] = useState(false);
    //shows sign in modal when user selects sign in
    // let [ showSignIn, setShowSignIn ] = useState(false);

    // const getModalFooter = (btnName) => {
    //     return (
    //         <>
    //             <SigninLink>
    //                 <Button className='solid-primary-btn'>
    //                     <Image
    //                         src={microsoftLogo}
    //                         alt='Microsoft Logo'
    //                         style={{
    //                             width: "auto",
    //                             height: "1.25em"
    //                         }}
    //                     />
    //                     {btnName}
    //                 </Button>
    //             </SigninLink>
    //             <p>By continuing, you agree to the 
    //                 <Link className='terms-of-service' to={{ pathname: "https://www.iubenda.com/terms-and-conditions/62216506" }} target="_blank">Terms of Service</Link>
    //                 and
    //                 <Link className='privacy-policy' to={{ pathname: "https://www.iubenda.com/privacy-policy/62216506" }} target="_blank">Privacy Policy</Link>
    //             </p>
    //         </>
    //     )
    // }

    // const getModalSubFooter = (type) => {
    //     return (
    //         type === 'create' ?
    //             <>
    //                 <BoxArrowInRight /> 
    //                 Have an account already? 
    //                 <SigninLink>
    //                     Log in
    //                 </SigninLink>
    //             </>
    //         : 
    //             type === 'sign-in' ?
    //                 <>
    //                     <PersonCheckFill            /> 
    //                     Dont have an account already? 
    //                     <SigninLink>
    //                         Sign up
    //                     </SigninLink>
    //                 </>
    //             : null
    //     )
    // }

    //modal content for the create user modal
    // const createModalContent = {
    //     className: 'article-load-modal',
    //     title: 'Create an Account',
    //     body: 'Creating an account will give you access to our exclusive news articles, allowing you to stay informed on topics that matter to you.',
    //     footer: getModalFooter("Create an Account"),
    //     subFooter: getModalSubFooter('create')
    // }

    //modal content for the sign in user modal
    // const signInModalContent = {
    //     className: 'article-load-modal',
    //     title: 'Sign in',
    //     body: 'Signing into your account will give you access to our exclusive news articles, allowing you to stay informed on topics that matter to you.',
    //     footer: getModalFooter("Sign in"),
    //     subFooter: getModalSubFooter('sign-in')
    // }

    //gets article count out of localStorage
    let articleCount = localStorage.getItem('artCount') ? parseInt(localStorage.getItem('artCount')) : 0;

    //strip querystring off if present
    let trackParams = new URL(document.location.href).searchParams;

    const { contentID } = useParams();
    // const isAuthenticated = useIsAuthenticated();

    //increments article count, calls the set in local storage function and shows the first modal so user can't continue reading
    const getArticleCount = (count) => {
        count = count + 1;
        setCountInStorage(count);
        if(count > 3) {
            // setShow(true)
        }
    }

    //Adds article count to local storage
    const setCountInStorage = (count) => {
        localStorage.removeItem('artCount')
        localStorage.setItem('artCount', count)
    }

    //gets article from agility
    const loadArticle = async () => {
        let addon = '';
        if(trackParams.has('ti')){
            addon = '&ti=' + trackParams.get('ti');
        }
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?id=${contentID}${addon}`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setArticle(jsonresponse);
    }

    useEffect( ()=> {
        setLoadingArticle(true);
        try {
            getArticleCount(articleCount)
            loadArticle();
            setLoadingArticle(false);
        } catch (error) {
            console.log(error);
            setLoadingArticle(false);
        }
    // eslint-disable-next-line    
    },[])

    return (
        !loadingArticle && article ?
            // isAuthenticated ?
                <FullStoryView article={article} />
            // :
            //     <>
            //         <FullStoryView article={article}/>
            //         <Modal className='article-load-modal' show={show}>
            //                 <div>
            //                     <h2>Sign up to continue reading</h2>
            //                     <p className='first-child'>
            //                         You've reached your limit to how many articles you can read. 
            //                         Please log in or sign up to discover more of what Linksbridge news
            //                         has to offer.
            //                     </p>
            //                     <Row className='btn-row'>
            //                         <Col xs={12} sm={6} className='first-col'>
            //                             <Button className='grey-primary-btn' onClick={() => setShowSignIn(!showSignIn)}><BoxArrowInRight />Sign In</Button>
            //                         </Col>
            //                         <Col xs={12} sm={6} className='sec-col'>
            //                             <Button className='solid-primary-btn' onClick={() => setShowCreate(!showCreate)}><PersonCheckFill />Create an Account</Button>
            //                         </Col>

            //                     </Row>
            //                     <p>By continuing, you agree to the 
            //                         <Link className='terms-of-service' to={{ pathname: "https://www.iubenda.com/terms-and-conditions/62216506" }} target="_blank">Terms of Service</Link>
            //                         and
            //                         <Link className='privacy-policy' to={{ pathname: "https://www.iubenda.com/privacy-policy/62216506" }} target="_blank">Privacy Policy</Link>
            //                     </p>
            //                 </div>
            //         </Modal>
            //         <ReusableModal 
            //             show={showSignIn}
            //             content={signInModalContent}
            //             handleClose={() => setShowSignIn(false)}
            //         />
            //         <ReusableModal 
            //             show={showCreate}
            //             content={createModalContent}
            //             handleClose={() => setShowCreate(false)}
            //         />
            //     </>
        : <Spinner animation="border"/>
    )
}

export default LbNewsArticlePage
