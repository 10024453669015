import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbDataUsePage({ stripes }) {
    
    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Data Use And Privacy Title',
                className: 'title-card',
                key: 'data-use-and-privacy-title'
            }]
        },
        {
            contents: [
                {
                    type: CONTENT_TYPES.STRIPE,
                    name: 'Data Use And Privacy Second Row',
                    className: 'standard-text-pic-card data-use-second-row',
                    key: 'data-use-and-privacy-second-row'
                }
            ]
        }
    ];


    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='data-use-page'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbDataUsePage
