import React, { useState } from 'react';
// import HubspotForm from 'react-hubspot-form';
import { Form, Button } from 'react-bootstrap';
// import Mailchimp from 'react-mailchimp-form';

function NewsletterSignUpForm({ signedUp, onSignUp, className }) {

  let [formSubmitted, setFormSubmitted] = useState(false);
  let [form, setForm] = useState({});
  let [errors, setErrors] = useState({});

  const onChange = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })

    // Looking to see if errors exist
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null
      })
    }
  }

  const findFormErrors = (formToCheck) => {
    const { email, firstname, lastname } = formToCheck;

    let newErrors = {};

    if (!email || email === '') {
      newErrors.email = 'Email is Required!';
    }
    if (!firstname || firstname === '') {
      newErrors.firstname = 'First name is required!';
    }
    if (!lastname || lastname === '') {
      newErrors.lastname = 'Last name is required!';
    }

    return newErrors;
  }

  const submitForm = (e) => {
    e.preventDefault();

    let body = {
      "fields": [
        {
          "name": "email",
          "value": form.email
        },
        {
          "name": "firstname",
          "value": form.firstname
        },
        {
          "name": "lastname",
          "value": form.lastname
        }
      ]
    }

    const formID = "4401f8f9-a97d-4278-92c6-5c50302293b6";
    const portalID = "8615684"

    const formErrors = findFormErrors(form);

    if (Object.keys(formErrors).length < 1) {
      try {
        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }).then(() => {
          setFormSubmitted(true);
        })
      } catch (err) {
        console.log(err);
      }
    } else {
      setErrors(formErrors);
    }
  }

  return (
    formSubmitted ?
      <p>You're Signed Up!  Thanks!</p>
      :

      <Form className='news-signup' onSubmit={(e) => submitForm(e)}>
        <Form.Group>
          <Form.Control
            id="emailInput"
            className='email'
            name='email'
            placeholder='Email'
            onChange={(e) => onChange(e.target.name, e.target.value)}
            isInvalid={ !!errors.email } 
          />
          <Form.Control.Feedback type='invalid'>
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Control
            id='firstNameInput'
            className='first-name'
            name='firstname'
            placeholder='First Name'
            onChange={(e) => onChange(e.target.name, e.target.value)}
            isInvalid={!!errors.firstname}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.firstname}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Form.Control
            id='lastNameInput'
            className='last-name'
            name='lastname'
            placeholder='Last Name'
            onChange={(e) => onChange(e.target.name, e.target.value)}
            isInvalid={ !!errors.lastname } 
            />
            <Form.Control.Feedback type='invalid'>
              {errors.lastname}
            </Form.Control.Feedback>
        </Form.Group>

        <Button
          type="submit"
          onClick={submitForm}
        >
          Submit
        </Button>
      </Form>
  )
}

export default NewsletterSignUpForm
