import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbHelpPage({ stripes }) {

    const pageLayout = [
        { 
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Help Title',
                className: 'title-card help-title-card',
                key: 'help-title'
            }]
        },
        {
            contents: [{
                type: CONTENT_TYPES.STRIPE,
                name: 'Help Explanation',
                className: 'help-info-card',
                key: 'help-explanation'
            }]
        },
        {
            contents: [{
                type: CONTENT_TYPES.FAQ,
                name: 'faq list',
                className: 'faq-list-card'
            }]
        },
        {
            contents: [{
                type: CONTENT_TYPES.SUPPORT_FORM,
                name: 'request support',
                className: ''
            }]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], [], stripes);
        })
    }

    return (
        <div className='help-page-layout'>
            <LBPageComponent mergedContent={mergedContent} />
        </div>
    )
}

export default LbHelpPage