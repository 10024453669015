import React from 'react';
import LBPageComponent from '../../components/LBPageComponent';

import { CONTENT_TYPES } from '../../utils/pageConsts';
import utils from '../../utils/utils';

function LbNoMatchPage({ stripes, blocks, misc }) {
    const pageLayout = [
        {
            contents: [
                {
                    type: CONTENT_TYPES.NO_MATCH,
                    title: 'No Match',
                    className: 'no-match-card',
                },
            ]
        }
    ];

    let mergedContent = [];

    if(stripes){
         mergedContent = pageLayout.map( (thisObj) => {
            return utils.mergeLayoutItemsWithData(thisObj.contents, [], blocks, stripes);
        })
    }

    return (
        <LBPageComponent mergedContent={mergedContent} 
            optionalDepContent={misc}/>
    )
}

export default LbNoMatchPage