import React from 'react';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import NewsTeasers from './NewsTeasers';

import { stripHtml } from "string-strip-html";

function NewsStripe({ stories, loadingTracker, keyForHighlightedArticle }) {
    let storyToHighlight;
    
    if(!keyForHighlightedArticle || keyForHighlightedArticle === 'LATEST'){
        storyToHighlight = stories[0];
    }
    
    let fixedBody = '';

    // const SHOW_NUM_ARTICLES = 4;

    // const getStoriesForTeaser = (storyList, numArticlesToShow) => {
    //     //this function will be able to deal with filtering stories for the teaser block
    //     let articlesToReturn = storyList.slice(1, numArticlesToShow + 1)

    //     return articlesToReturn;

    // }
    
    if(storyToHighlight && storyToHighlight.fields.body){
        fixedBody = stripHtml(storyToHighlight.fields.body).result.substr(0,250) + '...';
    }

    return (
        <>
        {
            !loadingTracker && stories ?
                <div className="full-news-row">
                    <Col className="linksbridge-news-col" >
                        <Row className="linksbridge-news-row">
                            <Card className="linksbridge-news-card"> 
                            { 
                                storyToHighlight && storyToHighlight.fields && storyToHighlight.fields.picture ? 
                                    <Card.Img src={storyToHighlight.fields.picture.url} alt={storyToHighlight.fields.picture.altText} />
                                : 
                                    <Card.Img  className="pharma-img" src='/images/PharmanewsCoverImage.jpg' alt='pharma news' />
                            }
                                <Card.Body>
                                    <Card.Title lg={3} className="linksbridge-news-title">Linksbridge News</Card.Title>
                                    <Card.Text>Our news team covers pharma industry developments important to global health.</Card.Text>
                                    <Link to={`/news`} >
                                        <Button className="btn-blue" to="/news">View stories</Button>
                                    </Link>
                                </Card.Body>
            
                            </Card>
                        </Row> 
                    </Col> 

                    <Col className="featured-news-col">
                        <Row className="featured-news-row"> 
                            {
                                storyToHighlight && storyToHighlight.fields ?
                                    <Card className="featured-news-card" >
                                        { 
                                            storyToHighlight.fields.picture ? 
                                            <Card.Img>{ storyToHighlight.fields.picture.url} alt={storyToHighlight.fields.picture.altText}</Card.Img>
                                            : null

                                        }

                                        { 
                                            storyToHighlight.fields.body ? 
                                            <Card.Text className="">* Featured article</Card.Text>
                                            : null
                                        }

                                        { 
                                            storyToHighlight.fields.title ? 
                                            <Card.Title className="highlighted-news-title">{ storyToHighlight.fields.title }</Card.Title>
                                            : null
                                        }
                                        
                                        { 
                                            storyToHighlight.fields.body ? 
                                            <Card.Text className="highlighted-news-body">{storyToHighlight.fields.articlePreviewText ? (storyToHighlight.fields.articlePreviewText) : fixedBody }</Card.Text>
                                            : null
                                        }

                                        {
                                            storyToHighlight.contentID ?
                                            <Link to={`/news/${storyToHighlight.contentID}`}>
                                                <Button className="btn-blue">Read more</Button>
                                            </Link>
                                            : null
                                        }
                                        
                                    </Card>
                                :''
                            }
                        </Row> 
                        </Col>
                
                    {/* <Col md={12} lg={6} >
                        <NewsTeasers 
                            className="news-stripe-stories" 
                            urlprefix={'/news/'}
                            stories={getStoriesForTeaser(stories, SHOW_NUM_ARTICLES)} 
                            showNumber={SHOW_NUM_ARTICLES} 
                            loadingTracker={loadingTracker} />
                    </Col> */}
                </div>
            : <Spinner animation='border' />
        }
        </>
    )
}

export default NewsStripe
