import React from 'react';
import { Badge } from 'react-bootstrap';

function TagDisplay({ tagList, className, topicNodeTags=null}) {

    return (
        <div className={(className ? className : '') + " tag-display"}>

            { topicNodeTags ? 
                topicNodeTags.map((topicTag, idx) => {
                        
                    return (
                        <a key={idx} href={`/topics/${topicTag.fields.tagtext}?tagQuery=true`}>
                        <Badge variant="info">{<span dangerouslySetInnerHTML={{ __html: topicTag.fields.tagtext }} />}</Badge>
                        </a>
                    )
                })
            : 
            tagList ? 
                tagList.map((thisTag, idx) => {
                    return (
                        thisTag.contentID ?
                            <a key={idx} href={`/news/topics/${thisTag.fields.tagtext}?tagQuery=true`}>
                                <Badge variant="info">{<span dangerouslySetInnerHTML={{ __html: thisTag.fields.tagtext }} />}</Badge>
                            </a>
                            :
                            <a key={idx} href={`/news/topics/${thisTag}?tagQuery=true`}>
                                <Badge variant="info">{<span dangerouslySetInnerHTML={{ __html: thisTag }} />}</Badge>
                            </a>
                    )
                })
                : ""
            }
            
        </div>
    )
}

export default TagDisplay