import React from 'react';
import { Card } from 'react-bootstrap';

import utils from '../utils/utils';

import NewsletterSignUpForm from './NewsletterSignUpForm';


function LBSignUpBox({ className,  titleText }) {
    return (
        <Card className={(className ? className : '') + 'signup-box'}>
            {
                titleText ? 
                    <Card.Body dangerouslySetInnerHTML={utils.getHTML(titleText)} />
                : null
            }
            <NewsletterSignUpForm />
        </Card>
    )
}

export default LBSignUpBox
