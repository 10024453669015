import React, { useState, useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import RepeatedSmallItemStripe from './RepeatedSmallItemStripe';

function LBValuesStripe() {

    let [ contentsLoading, setContentsLoading ] = useState(true);
    let [ valuesList, setValuesList ] = useState();

    const loadValuesList = async () => {
        
        let response = await fetch(`${process.env.REACT_APP_WEB_API}cms-content?contenttype=LBValues`, {
            headers: {
              "x-functions-key": process.env.REACT_APP_GVMM_KEY
            }
        });

        let jsonresponse = await response.json();

        setValuesList(jsonresponse.items);
    }



    useEffect( () => {
        setContentsLoading(true);
        try {
            loadValuesList();
            setContentsLoading(false);
        } catch ( error ) {
            console.log(error);
            setContentsLoading(false);
        }
    },[])

    return (
            contentsLoading ?
                <Container>
                    <Spinner animation="border"/>
                </Container>
                
        :   

        <RepeatedSmallItemStripe 
                className="lb-values-stripe" 
                contentList={valuesList}  />
    )
}

export default LBValuesStripe
