import appRoutes from './routes';
import * as msal from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import { LastLocationProvider } from 'react-router-last-location';
import { Container, Row, Col } from 'react-bootstrap';

// import { Callback } from "./components/auth/CallBack";
// import { Logout } from "./components/auth/Logout";
// import { LogoutCallback } from "./components/auth/LogoutCallback";
// import { PrivateRoute } from "./components/PrivateRoute";
// import { SilentRenew } from "./components/auth/SilentRenew";
import { useEffect } from 'react';

import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './components/AppInsights';

import utils from './utils/utils';

import './styles/main.scss';

function App() {

  const msalConfig = {
    auth: {
      clientId: '236c33de-553c-4b1a-8965-7e4c148cc57a',
      redirectUri: `${window.location.origin}/blank.html`,
      authority: 'https://login.microsoftonline.com/08da5958-7890-484c-b92c-ba495b4218bd'
    },
    cache: {
      cacheLocation: "localStorage",//keeps login token across sites
      storeAuthStateInCookie: true //stores the auth state in your cache
    }
  };

  let msalInstance = null;

  useEffect( () => {
    utils.initializeTracking();
  });

  const displayError = () => {
    return (
      <Container>
        <Row>
          <Col>
            <img src="/images/bad-dog.png" alt="bad dog!" />
          </Col>
          <Col>
            <h1>Oops!</h1>
            <p> Sorry - something went wrong.  We've logged it so we can fix it - try refreshing your browser and see if it goes away.</p>
          </Col>
        </Row>
      </Container>
    )
  }


  msalInstance = new msal.PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <Router>
        <LastLocationProvider>
          <AppInsightsErrorBoundary onError={displayError} appInsights={reactPlugin}>
            <div className="App">
                <Route
                    path={appRoutes.LINKSBRIDGE_ROUTE.path}
                    component={appRoutes.LINKSBRIDGE_ROUTE.component}
                    />
            </div>
          </AppInsightsErrorBoundary>
        </LastLocationProvider>
      </Router>
    </MsalProvider>
  );
}

export default App;
