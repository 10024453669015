import React from 'react'
import FeaturePageDisplay from './FeaturePageDisplay'

function FeaturePageWrapper({rawFeatureTopic, preview=false}) {

  const adaptInput = (featurePages) => {

      const retObj = {
        pageTitle: featurePages.fields.pageTitle ? featurePages.fields.pageTitle :'',
        showAuthor: featurePages.fields.showAuthor ? featurePages.fields.showAuthor : 'false',
        authorName: featurePages.fields.authorName ? featurePages.fields.authorName : '',
        datePublished: featurePages.fields.datePublished ? featurePages.fields.datePublished : '',
        bannerImage: featurePages.fields.bannerImage ? featurePages.fields.bannerImage : '',
        mainContent: featurePages.fields.mainContent ? featurePages.fields.mainContent : '',
        articlePreviewText: featurePages.fields.articlePreviewText ? featurePages.fields.articlePreviewText : '',
        tags: featurePages.fields.tags ? featurePages.fields.tags : '',
        channels: featurePages.fields.channels ? featurePages.fields.channels : '',
        pageKeyURL: featurePages.fields.pageKeyURL ? featurePages.fields.pageKeyURL : ''
      }
 
    return retObj;
}

  return (
    rawFeatureTopic ?
      <div>
        <FeaturePageDisplay
          featureToDisplay={adaptInput(rawFeatureTopic)}
          preview={preview}
        />
      </div>
    : ''
  )
}

export default FeaturePageWrapper