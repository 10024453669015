import React, { useEffect } from 'react';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import useScrollToLocation from './useScrollToLocation';
import utils from '../utils/utils';
import TagDisplay from './TagDisplay';

function FullStoryView({ article, className }) {

    let lastLocation = useLastLocation();
    let backLink = '/';

    useScrollToLocation();

    if (lastLocation) {
        backLink = lastLocation;
    }

    const sendTrackingInfo = async (artToSend) => {
        let artTags = artToSend.fields.tags_TextField.split(',');

        await utils.upsert('insert_content_log', process.env.REACT_APP_DB_PUBLIC_KEY, 
                {
                    user: '',
                    tags: artTags,
                    title: artToSend.fields.title,
                    content_id: artToSend.contentID,
                    access_date: new Date(),
                    accessing_site:'linksbridge'
                }
            )
    };

    useEffect(() => {
        if(article) {
            document.title = article.fields.title;
            sendTrackingInfo(article);
        }
    }, [article]);

    const getHTMLContent = (art) => {
        let artURL=utils.removeURLPlaceholder(art.fields.links, 'news');
        let addTarget = utils.addTargetToLinksInHtml(artURL)
        return utils.getHTML(addTarget);
    } 

    return (
        article ?
            <Container className="margin-at-top news-article-display">
                <Row  className={(className ? className : '') + ' full-article-stripe'}>
                    <Col md={6}>
                        <Link className="back-link" to={backLink}> Back To Previous</Link>
                        <h3>{article.fields.title}</h3>
                        <small className="full-article-date">{ utils.getDateString(article.fields.datepublished, true) + " " }</small>
                        {article.fields.body ?
                            <p className='article-body' dangerouslySetInnerHTML={utils.getHTML(article.fields.body)} />
                            : null}
                        {article.fields.links ?                       
                            <p className="article-links" dangerouslySetInnerHTML={getHTMLContent(article)} />
                            : null}
                        {

                            <TagDisplay tagList={article.fields.tags ? article.fields.tags : ""} />

                        }
                    </Col>
                </Row>
            </Container>
            :
            <Spinner animation='border' />
    )
}

export default FullStoryView