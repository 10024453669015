export const CONTENT_TYPES = {
    STRIPE: 'LBContentStripes',
    TEASER: 'mainpageteasers',
    SIGNUPBOX: 'signupbox',
    SIGNUP_TEASER: 'signupteaser',
    NEWS_TEASER: 'newspageteasers',
    NEWS_EXPLORE: 'newsexploreteasers',
    TEAM_LIST: 'EmployeeProfiles',
    LINKED_IMAGE_STRIPE_GIFTS: 'LBGiftsGiven',
    VALUES_LIST: 'LBValues',
    JOB_LIST: 'joblist',
    PROJECTS_LIST: 'projectslist',
    NEWS_STRIPE: 'newsstripe',
    NO_MATCH: 'nomatch',
    FAQ: 'faqstripe',
    STORY_EDIT_LIST: 'storyeditlist',
    HUBSPOT_EDIT_LIST: 'hubspoteditlist',
    HUBSPOT_EDIT_LIST_V2: 'hubspoteditlistv2',
    HUBSPOT_EDIT_LIST_BMGF:'hubspoteditlistbmgf',
    SUPPORT_FORM: 'supportform',
    POLICIES: 'policies',
    TRENDING_TOPICS_STRIPE: 'trending_topics',
    TOPIC_HUB_PAGES: 'topic_hub_pages'
}