import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { stripHtml } from "string-strip-html";
import TagDisplay from './TagDisplay';
import utils from '../utils/utils';

function StoryTeaserLine({ story, urlprefix, showTags, noImage, trackingInfo=null }) {

    const getLink = (urlprefix, trackingInfo, storyContentID) => {
        let theURL = urlprefix ? urlprefix : '/profiles/companies/news/';
        theURL = theURL + storyContentID;
        theURL = theURL + ( trackingInfo ? '?ti=' + trackingInfo : ''); 
        return theURL;
    }

    const returnStoryGuts = () => {

        let fixedBody = stripHtml(story.fields.body).result.substr(0,150) + '...';
        
        return (
            <div class="story-teaser">
                <h3>{ story.fields.title }</h3>
                <p className="smaller-body">
                    { story.fields.preview_text ? (story.fields.preview_text) : fixedBody }
                </p>
                
                <small>
                    { utils.getDateString(story.fields.datepublished, true) + " | " }
                    { story.readTime ? story.readTime + ' read': null }
                </small>
            </div>
        );
    }


    const limitTags = story.fields.tags.slice(0, 6) 
    // limits shown tags to a max of 6: called on StoryTeaserLine, but not on FullStoryView

        return (
        <Row className="story-line-row">
            {
                (story.fields && story.fields.showNoImage && story.fields.showNoImage === true) || noImage ?
                    null
                :
                <Col xs={3}>
                    {
                        story.fields &&  story.fields.image && story.fields.image.length > 0 ?
                            <Image src={story.fields.image} thumbnail />
                        : null
                    }
                </Col>
            }
            <Col xs={ (story.fields && story.fields.showNoImage && story.fields.showNoImage === true) ? 12 : 9 }>
                <Link to={getLink(urlprefix, trackingInfo, story.contentID)}>
                {    
                    returnStoryGuts() 
                }
                </Link>
                { showTags && story.fields.tags ? 
                        <div>
                            <TagDisplay tagList={limitTags} />
                        </div>
                    : null
                }
            </Col>
        </Row>
    )
}

export default StoryTeaserLine
